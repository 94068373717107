import CSVImport from '$blocks/CSVImport'

const AppPermissionCSVImport = () => {
  return (
    <article>
      <CSVImport importType="permission" />
    </article>
  )
}

export default AppPermissionCSVImport
