import { cn } from '$app/utils'
import { TDeleteItemType } from '$components/DeleteDoubleConfirmPopUp/types'
import { defaultBackground, defaultBorder, shadow } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { FC, PropsWithChildren } from 'react'
import { DELETE_INFO } from './constants'

const { colors } = themeVars

const DangerZone: FC<PropsWithChildren<{ type: TDeleteItemType }>> = ({
  children,
  type
}) => {
  const { title, desc } = DELETE_INFO[type]

  return (
    <article
      className={cn(
        'flex flex-col px-12 py-7 rounded-lg gap-6',
        defaultBackground,
        defaultBorder
      )}
    >
      <label className="font-semibold">DANGER ZONE</label>

      <article
        className={cn(
          'flex items-center px-12 py-7 rounded-lg gap-4',
          shadow.small
        )}
        style={{ background: colors.alphaDanger[0] }}
      >
        <article className="flex flex-col gap-y-1 flex-1 px-3">
          <p className="font-semibold uppercase">{title}</p>
          <p className="text-xs" style={{ color: colors.neutral[50] }}>
            {desc}
          </p>
        </article>

        {children}
      </article>
    </article>
  )
}

export default DangerZone
