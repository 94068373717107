import { cloneElement, createElement, forwardRef, isValidElement } from 'react'

import { buildURL, getDetail, RedirectOptions, RouteNames } from './config'
import { useRedirectProxy, useRoute } from '$contexts/RouteContext/hooks'

type Tag = JSX.IntrinsicElements['a']

export type LinkProps = {
  to: RouteNames
  options?: RedirectOptions
} & Omit<Tag, 'href'>

const Link = forwardRef<Tag, LinkProps>(
  (
    {
      children,
      to,
      options,
      onClick: onNativeClick = () => undefined,
      ...props
    },
    ref
  ) => {
    const isJSX = isValidElement(children)

    const redirect = useRedirectProxy()

    const currentRoute = useRoute()

    const redirectOptions = (() => {
      if (typeof options == 'function') {
        const { params, hash, queryParams } = currentRoute

        return options({
          hash,
          params,
          queryParams
        })
      }

      return options
    })()

    const { pathname } = getDetail(to)
    const href = buildURL(pathname, redirectOptions).toString()

    const onClick: LinkProps['onClick'] = e => {
      const hasModKeys = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey

      onNativeClick(e)

      if (e.defaultPrevented || hasModKeys) return

      e.preventDefault()

      redirect(to, redirectOptions)
    }

    const passProps = { href, onClick, ref, ...props }

    if (isJSX) return cloneElement(children, passProps)

    return createElement('a', { children, ...passProps })
  }
)

export default Link
