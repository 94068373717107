import clsx, { ClassValue } from 'clsx'
import { APP_TITLE } from './services/environments'
import { twMerge } from 'tailwind-merge'
import { configUpload } from '$services/api'
import axios from 'axios'
import { format, isValid } from 'date-fns'
import { ALLOWED_SPECIAL_CHARACTERS } from './constants'

export const setLocalStorage = (key: string, value: unknown) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorage = <T>(key: string): T | undefined => {
  const storedValue = localStorage.getItem(key)
  return storedValue ? JSON.parse(storedValue) : undefined
}

export const removeLocalStorage = (key: string) => {
  localStorage.removeItem(key)
}

export type TypeAppTitle = {
  title?: string
  suffix?: string
  addSuffix?: boolean
  returnAppTitle?: boolean
}

export const getAppTitle = (options: TypeAppTitle = {}): string => {
  const {
    title,
    suffix = APP_TITLE,
    addSuffix = true,
    returnAppTitle = true
  } = options

  if (title === undefined && returnAppTitle) {
    return APP_TITLE
  }

  if (addSuffix) {
    return `${title} - ${suffix}`
  }

  return title || ''
}

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export type TUploadFileData = {
  file_name: string
  key: string
  file: File
  preview: string
}

export const uploadFile = async (file: File): Promise<TUploadFileData> => {
  const { url, key, preview } = await configUpload()

  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
      Accept: 'application/json'
    }
  })

  return {
    file_name: file.name.split('.').slice(0, -1).join('.'), // remove extension
    key,
    file,
    preview
  }
}

export const formatFileSize = (size: number) => {
  const fileSizeUnit = ['KB', 'MB', 'GB', 'TB']

  let value = size ?? 0

  let unit = 'B'

  if (value) {
    const initial = fileSizeUnit.length

    for (let i = initial; i >= 0; i--) {
      const divider = 1024 * 1024 ** i

      if (size >= divider) {
        value = size / divider

        unit = fileSizeUnit[i]

        break
      }
    }
  }

  return `${+value.toFixed(2)} ${unit}`
}

export const getFlagUrl = (code: string) => {
  return `https://flagcdn.com/${code.toLocaleLowerCase()}.svg`
}

export const getPhone = (
  phone_code?: string | null,
  phone_no?: string | null
) => {
  const tempArr = [phone_code, phone_no]
    .map(v => (v ? v.replaceAll('N/A', '') : ''))
    .filter(Boolean)

  if (tempArr.length) return tempArr.join(' ')

  return '-'
}

export const getDateOfBirth = (date: string) => {
  if (!date) return '-'

  return isValid(new Date(date)) ? format(date, 'dd MMM yyyy') : date
}

export const removeNAText = (value?: string) => {
  const text = 'N/A'
  if (!value || value === text) return '-'
  return value
}

export const formatNumber = (value: number) => {
  return value.toLocaleString()
}

export const getRandomChar = (chars: string) =>
  chars[Math.floor(Math.random() * chars.length)]

export const shuffleString = (str: string) => {
  const splitStrArr = str.split('')
  for (let i = splitStrArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[splitStrArr[i], splitStrArr[j]] = [splitStrArr[j], splitStrArr[i]]
  }
  return splitStrArr.join('')
}

export const generatePassword = (pwdLength: number) => {
  const lowercase = 'abcdefghijklmnopqrstuvwxyz'
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numbers = '0123456789'
  const specialChars = ALLOWED_SPECIAL_CHARACTERS.join('')

  const category = [lowercase, uppercase, numbers, specialChars]

  let str = ''

  for (let i = 0; i < pwdLength; i++) {
    const idx = i % category.length
    const randomChar = getRandomChar(category[idx])
    str += randomChar
  }

  return shuffleString(str)
}
