import { cn, getPhone, removeNAText } from '$app/utils'
import CountryBadge from '$components/CountryBadge'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TAppGroupDetail } from '$services/api'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { ArrowLeft, PenLine } from 'lucide-react'
import { FC, PropsWithChildren, useCallback } from 'react'

const { colors } = themeVars

const Label: FC<PropsWithChildren> = ({ children }) => {
  return (
    <label
      className="font-medium text-xs"
      style={{ color: colors.neutral[60] }}
    >
      {children}
    </label>
  )
}

const Value: FC<PropsWithChildren> = ({ children }) => {
  return (
    <p className="font-semibold text-sm" style={{ color: colors.text.light }}>
      {children}
    </p>
  )
}

interface IProps {
  group: TAppGroupDetail
}

const GroupInfo = ({ group }: IProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const onBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_GROUPS, { params })
  }, [params])

  const onEdit = useCallback(() => {
    redirect(ROUTE_NAMES.APP_GROUP_EDIT, { params })
  }, [params])

  return (
    <article
      className={cn(
        'flex flex-col rounded-lg',
        defaultBackground,
        defaultBorder
      )}
    >
      <header
        className="flex items-center gap-2 px-4 py-3 border-b"
        style={{ borderColor: colors.neutral[10] }}
      >
        <Button onClick={onBack} styleVariants={{ type: 'text' }}>
          <ArrowLeft size={20} />
        </Button>

        <p
          className="flex-1 text-xl font-semibold"
          style={{ color: colors.text.light }}
        >
          {group.name}
        </p>

        <Button
          styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
          onClick={onEdit}
        >
          <PenLine size={16} />
          Edit
        </Button>
      </header>

      <main className="flex flex-col px-12 py-7 gap-4">
        <article className="flex flex-col gap-1">
          <p className="font-semibold">GROUP INFO</p>
          <p className="text-xs" style={{ color: colors.neutral[50] }}>
            View detailed information about each group to manage roles and
            permissions effectively.
          </p>
        </article>

        <article className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <article className="flex flex-col gap-0.5">
            <Label>Country</Label>
            <CountryBadge name={group.country.name} />
          </article>

          <article className="flex flex-col gap-0.5">
            <Label>Group Name</Label>
            <Value>{group.name}</Value>
          </article>

          <article className="flex flex-col gap-0.5">
            <Label>Phone Number</Label>
            <Value>{getPhone(group.phone_code, group.phone_no)}</Value>
          </article>

          <article className="flex flex-col gap-0.5">
            <Label>Description</Label>
            <Value>{removeNAText(group.description)}</Value>
          </article>
        </article>
      </main>
    </article>
  )
}

export default GroupInfo
