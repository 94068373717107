import { Size } from './types'

export type TData = Record<Size, number>

export const SIZE: { container: TData; image: TData } = {
  container: {
    small: 36,
    big: 48
  },
  image: {
    small: 24,
    big: 32
  }
}
