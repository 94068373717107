import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

const CountryBadge = ({ name }: { name: string }) => {
  return (
    <article>
      <article
        className="flex-none inline-flex px-3 py-0.5 rounded font-medium text-xs uppercase whitespace-nowrap"
        style={{
          background: colors.alphaTertiary[1],
          color: colors.tertiary[markedDefaultKey]
        }}
      >
        {name}
      </article>
    </article>
  )
}

export default CountryBadge
