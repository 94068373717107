import { useCallback, useState } from 'react'
import { APP_STATUS } from '../types'
import { useAppPublishUnpublishService } from '$hooks/services/useAppPublishUnpublishService'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { toast } from 'sonner'
import { useAppBasicSettingService } from '$hooks/services'

export const useAppPublishUnpublishAction = (status: APP_STATUS) => {
  const [showConfirmPopup, setShowConfirmPopUp] = useState(false)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { updateAppStatusAsync, updatingAppStatus } =
    useAppPublishUnpublishService()

  const { fetchAppBasicSettingAsync, fetchingAppBasicSetting } =
    useAppBasicSettingService()

  const onTrigger = useCallback(() => {
    setShowConfirmPopUp(true)
  }, [])

  const onCancel = useCallback(() => {
    setShowConfirmPopUp(false)
  }, [])

  const onConfirm = useCallback(async () => {
    if (!appId) return
    await updateAppStatusAsync({ appId, status }).then(() => {
      toast.success(`Successfully ${status}ed!`)
    })

    // update app state
    await fetchAppBasicSettingAsync({ application_id: appId })

    onCancel()
  }, [appId, updateAppStatusAsync, status, fetchAppBasicSettingAsync, onCancel])

  const isProcessing = updatingAppStatus || fetchingAppBasicSetting

  return {
    isProcessing,
    showConfirmPopup,

    onCancel,
    onConfirm,
    onTrigger
  }
}
