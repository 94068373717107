import { themeVars } from '@genie-fintech/ui/style/theme'
import { CheckCircle } from 'lucide-react'

const { colors } = themeVars

const DashboardAdminMessage = () => {
  return (
    <>
      <article
        className="inline-grid place-items-center w-16 h-16 rounded-full"
        style={{ background: colors.alphaSuccess[0] }}
      >
        <CheckCircle size={32} style={{ color: colors.success[80] }} />
      </article>

      <article className="flex flex-col gap-1 items-center">
        <p className="font-semibold">ALL APPLICATIONS CAN BE MANAGED</p>
        <p className="text-xs" style={{ color: colors.neutral[50] }}>
          This user has access on all applications to manage on Carro SSO.
        </p>
      </article>
    </>
  )
}

export default DashboardAdminMessage
