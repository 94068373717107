import { TDeleteItemType } from '$components/DeleteDoubleConfirmPopUp/types'

export const DELETE_INFO: Record<
  TDeleteItemType,
  { title: string; desc: string }
> = {
  application: {
    title: 'delete application setting',
    desc: 'Deleting the app is an irreversible action. All data will be erased and cannot be retrieved.'
  },
  group: {
    title: 'delete group setting',
    desc: 'Deleting the group is an irreversible action. All data will be erased and cannot be retrieved.'
  },
  app_user: {
    title: 'user termination',
    desc: 'Terminating the user is an irreversible action.'
  },
  role: {
    title: 'delete role setting',
    desc: 'Deleting the role is an irreversible action. All data will be erased and cannot be retrieved.'
  },
  permission: {
    title: 'delete permission setting',
    desc: 'Deleting the permission is an irreversible action. All data will be erased and cannot be retrieved.'
  },
  user: {
    title: 'user termination',
    desc: 'Terminating the user is an irreversible action.'
  }
}
