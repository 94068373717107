import { redirect, ROUTE_NAMES } from '$app/router/config'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { BaseText, Password } from '@genie-fintech/ui/components/hook-fields'
import { useMount } from 'ahooks'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { Button } from '@genie-fintech/ui/components/Button'
import {
  loginSchema,
  TLoginFormValues,
  useLoginLogoutService
} from '$hooks/services'
import { Logo } from '$assets/svg'
import {
  CopyRightText,
  FormCardStyle,
  FormContainerStyle,
  LoginContainerStyle,
  TitleText
} from './styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import LayoutWithBackground from '$layouts/LayoutWithBackground'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'

const { colors } = themeVars

const Login = () => {
  const { token, user } = useAuthContext()

  const isMounted = useIsMounted()

  const { login, loggingIn } = useLoginLogoutService()

  const { handleSubmit, control } = useForm<TLoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  useMount(() => {
    const hasSession = !!token && !!user

    if (hasSession) {
      redirect(ROUTE_NAMES.INDEX, { replace: true })
    }
  })

  return (
    <LayoutWithBackground
      className={LoginContainerStyle}
      data-ready={trueOrUndefined(isMounted)}
    >
      <form className={FormContainerStyle} onSubmit={handleSubmit(login)}>
        <Logo color="absolute.light" />

        <article className={FormCardStyle}>
          <article className="flex flex-col gap-1">
            <p className={TitleText}>Login</p>

            <p style={{ color: colors.neutral[70] }}>
              To access the Carro SSO service dashboard, please login here.
            </p>
          </article>

          <article className="flex flex-col gap-4">
            <BaseText
              label="Email"
              inputProps={{ type: 'email', autoComplete: 'email' }}
              name="email"
              control={control}
              required
            />

            <Password
              label="Password"
              name="password"
              control={control}
              required
            />

            <Button type="submit" disabled={loggingIn}>
              Login
            </Button>
          </article>
        </article>

        <p className={CopyRightText}>
          ©Carro SSO Service. All rights reserved.
        </p>
      </form>
    </LayoutWithBackground>
  )
}

export default Login
