import PasswordStateMessage from '$components/PasswordStateMessage'
import {
  checkPolicy,
  PASSWORD_POLICY_CONFIG
} from '$pages/PasswordPolicy/constants'
import { TPasswordPolicy } from '$services/api'

interface IProps {
  pwd: string
  policies: TPasswordPolicy[]
}

const PasswordPolicyChecker = ({ pwd, policies }: IProps) => {
  return (
    <article className="flex flex-col">
      {policies.map((policy, key) => {
        const message =
          PASSWORD_POLICY_CONFIG.find(d => d.id === policy.id)?.label ?? ''
        return (
          <PasswordStateMessage
            key={key}
            message={
              policy.min_length
                ? message.replace('###', policy.min_length + '')
                : message
            }
            isPass={checkPolicy(policy, pwd)}
          />
        )
      })}
    </article>
  )
}

export default PasswordPolicyChecker
