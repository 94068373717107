import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

const TodayBadge = () => {
  return (
    <article
      className="inline-flex items-center gap-1 px-1.5 py-px rounded"
      style={{ background: colors.alphaPrimary[1] }}
    >
      <span
        className="w-1 h-1 rounded-full"
        style={{ background: colors.primary[markedDefaultKey] }}
      />

      <span
        className="text-xs font-medium"
        style={{ color: colors.primary[markedDefaultKey] }}
      >
        TODAY
      </span>
    </article>
  )
}

export default TodayBadge
