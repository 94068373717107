import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppHomeService } from '$hooks/services'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import PublishAppSetting from '$blocks/AppHome/sections/PublishAppSetting'
import AppSummaryCard from '$blocks/AppHome/sections/AppSummaryCard'
import { ItemType } from '$blocks/AppHome/sections/AppSummaryCard/types'
import Last30MinUserChart from '$blocks/AppHome/sections/Last30MinUserChart'
import DailyActiveUserChart from '$blocks/AppHome/sections/DailyActiveUserChart'
import { WEEK_TYPE } from '$blocks/AppHome/sections/DailyActiveUserChart/types'
import Last30MinLoginDevices from '$blocks/AppHome/sections/Last30MinLoginDevices'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'

const AppHome = () => {
  const [startDate, setStartDate] = useState(new Date())

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { fetchAppHome, appHome } = useAppHomeService()

  const {
    user_count = 0,
    group_count = 0,
    role_count = 0,
    today_active_user_count = 0,
    recent_active_users = [],
    active_users = []
  } = appHome ?? {}

  useEffect(() => {
    if (!appId) return
    fetchAppHome({
      application_id: appId,
      range: 6,
      date: format(startDate, 'yyyy-MM-dd')
    })
  }, [appId, fetchAppHome, startDate])

  const onChangeWeek = useCallback((weekType: WEEK_TYPE) => {
    const date = new Date()

    if (weekType === WEEK_TYPE.THIS_WEEK) {
      setStartDate(date)
      return
    }

    if (weekType === WEEK_TYPE.LAST_WEEK) {
      date.setDate(date.getDate() - 7)
      setStartDate(date)
      return
    }
  }, [])

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <article className="flex flex-col gap-1.5">
        <PublishAppSetting />

        <article className="grid lg:grid-cols-3 gap-1.5">
          <AppSummaryCard item={ItemType.USER} count={user_count} />
          <AppSummaryCard item={ItemType.ROLE} count={role_count} />
          <AppSummaryCard item={ItemType.GROUP} count={group_count} />
        </article>

        <article className="grid grid-cols-1 lg:grid-cols-2 gap-1.5">
          <Last30MinUserChart recent_active_users={recent_active_users} />

          <DailyActiveUserChart
            active_users={active_users}
            today_active_user_count={today_active_user_count}
            currentDate={startDate}
            onChangeWeek={onChangeWeek}
          />
        </article>

        <Last30MinLoginDevices recent_active_users={recent_active_users} />
      </article>
    </>
  )
}

export default AppHome
