import { Button } from '@genie-fintech/ui/components'
import { ChangeEvent, useCallback, useRef } from 'react'
import { ALLOWED_FILE_TYPES } from '../../constants'

interface IProps {
  btnText: string
  onChangeFile: (e: ChangeEvent<HTMLInputElement>) => void
}

const accept = ALLOWED_FILE_TYPES.map(v => v.type).join(',')

const FileUploadButton = ({ btnText, onChangeFile }: IProps) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null)

  const onClick = useCallback(() => {
    inputFileRef.current?.click()
  }, [])

  return (
    <>
      <Button styleVariants={{ type: 'text', size: 'small' }} onClick={onClick}>
        {btnText}
      </Button>

      <input
        ref={inputFileRef}
        type="file"
        value=""
        onChange={onChangeFile}
        className="hidden"
        accept={accept}
      />
    </>
  )
}

export default FileUploadButton
