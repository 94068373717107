import { cn } from '$app/utils'
import Breadcrumb from '$components/Breadcrumb/v2'
import Loading from '$components/Loading'
import ModKey from '$components/ModKey'
import Pagination from '$components/Pagination'
import { useApiListingParams, useFocusEventLister } from '$hooks/actions'
import { useGlobalDashboardUserService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TGlobalDashboardUserListResponse, TID } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  defaultBorder
} from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { capitalize } from 'lodash-es'
import { Plus } from 'lucide-react'
import { useCallback, useEffect, useRef } from 'react'

const { colors } = themeVars

const columns: {
  key: Exclude<keyof TGlobalDashboardUserListResponse['data'][number], 'id'>
  value: string
}[] = [
  { key: 'name', value: 'NAME' },
  { key: 'email', value: 'EMAIL' },
  { key: 'type', value: 'ACCOUNT TYPE' },
  { key: 'role', value: 'ACCOUNT ROLE' }
]
const GlobalDashboardUser = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fetchGlobalDashboardUserList,
    fetchingGlobalDashboardUserList,
    dashboardUsers: { list, meta }
  } = useGlobalDashboardUserService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    fetchGlobalDashboardUserList({
      page,
      per_page: perPage,
      q: debouncedSearchValue
    })
  }, [fetchGlobalDashboardUserList, page, perPage, debouncedSearchValue])

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSearchValue(e.currentTarget.value)
    },
    [updateSearchValue]
  )

  useFocusEventLister(() => {
    inputRef.current?.focus()
  })

  const onAddUser = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_CREATE)
  }, [])

  const onClickRow = useCallback((dashboardUserId: TID) => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL, {
      params: { dashboardUserId }
    })
  }, [])

  return (
    <>
      <Breadcrumb category={ROUTE_NAMES.GLOBAL_DASHBOARD_USERS} />

      <article className="flex-1 flex flex-col gap-2">
        <header className="flex items-end gap-1 px-4 py-3">
          <article className="flex flex-col flex-1">
            <p className="font-semibold" style={{ fontSize: 32 }}>
              Total Dashboard Users - {meta?.total.toLocaleString()}
            </p>
            <p
              className="text-sm font-medium"
              style={{ color: colors.text.disabled }}
            >
              All the users of different admin or service roles in Carro SSO are
              here.
            </p>
          </article>

          <article className="flex gap-2 items-center">
            <BaseText
              inputRef={inputRef}
              containerProps={{ className: 'min-w-[250px]' }}
              affix={{
                pre: <Icon namespace="Search" width={16} />,
                post: searchValue ? (
                  <button onClick={() => updateSearchValue('')}>
                    <Icon namespace="Cross" width={18} />
                  </button>
                ) : (
                  <p className="flex gap-x-1 items-center text-[--colors-text-disabled] text-sm">
                    <span>
                      <ModKey />
                    </span>
                    <span>K</span>
                  </p>
                )
              }}
              inputProps={{
                type: 'text',
                value: searchValue,
                onChange: handleOnChange,
                placeholder: 'Search here...'
              }}
            />

            <Button onClick={onAddUser}>
              <Plus size={16} />
              <span className="whitespace-nowrap">Add User</span>
            </Button>
          </article>
        </header>
        <main
          className={cn(
            'flex flex-col gap-6 rounded-lg px-12 py-7',
            defaultBackground,
            defaultBorder
          )}
        >
          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      NO
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className={tableBody}>
                  {list.map((data, rowKey) => (
                    <tr
                      key={rowKey}
                      className={cn(tableRow, 'cursor-pointer')}
                      onClick={() => onClickRow(data.id)}
                    >
                      <td className={customTableCell}>
                        {(page - 1) * perPage + rowKey + 1}
                      </td>
                      {columns.map((col, colKey) => (
                        <td key={colKey} className={customTableCell}>
                          {(() => {
                            if (['type', 'role'].includes(col.key))
                              return capitalize(data[col.key])

                            return data[col.key] ?? 'N/A'
                          })()}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {!list.length && !fetchingGlobalDashboardUserList && (
                    <tr className={tableRow}>
                      <td
                        colSpan={columns.length + 1}
                        style={{ textAlign: 'center' }}
                        className={customTableCell}
                      >
                        NO DATA
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </main>
          </article>

          {fetchingGlobalDashboardUserList && <Loading />}

          {!!list.length && (
            <footer className="flex px-4 py-2">
              <Pagination meta={meta} pagerProps={pagerProps} />
            </footer>
          )}
        </main>
      </article>

      {/* <section className="flex flex-1 flex-col relative gap-2">
        <header className="flex flex-col">
          <p className="text-[32px] font-semibold text-[--colors-text-default]">
            Total Dashboard Users - {meta?.total.toLocaleString()}
          </p>

          <article className="flex flex-wrap items-center justify-between gap-x-2">
            <p className="flex-1 text-[--colors-text-disabled] text-sm font-medium">
              Setup a mobile, web or IoT application to use Auth0 for
              Authentication.
            </p>

            <article>
              <BaseText
                inputRef={inputRef}
                containerProps={{ className: 'min-w-[250px]' }}
                affix={{
                  pre: <Icon namespace="Search" width={16} />,
                  post: searchValue ? (
                    <button onClick={() => updateSearchValue('')}>
                      <Icon namespace="Cross" width={18} />
                    </button>
                  ) : (
                    <p className="flex gap-x-1 items-center text-[--colors-text-disabled] text-sm">
                      <span>
                        <ModKey />
                      </span>
                      <span>K</span>
                    </p>
                  )
                }}
                inputProps={{
                  type: 'text',
                  value: searchValue,
                  onChange: handleOnChange,
                  placeholder: 'Search applications...'
                }}
              />
            </article>

            <Button onClick={onAddUser}>
              <Plus size={20} />
              Add User
            </Button>
          </article>
        </header>

        <article className="flex flex-col flex-1 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg px-5 py-2">
          <article className={cn(customTableContainer, 'flex-1 flex flex-col')}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      NO
                    </th>
                    <th className={customTableCell} style={{ width: 0 }}>
                      <Avatar size={30} />
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>

                {!fetchingGlobalDashboardUserList && (
                  <tbody className={tableBody}>
                    {list.map((data, rowKey) => (
                      <tr key={rowKey} className={tableRow}>
                        <td className={customTableCell}>
                          {(page - 1) * perPage + rowKey + 1}
                        </td>
                        <td className={customTableCell}>
                          <Avatar size={30} />
                        </td>
                        {columns.map((col, colKey) => (
                          <td key={colKey} className={customTableCell}>
                            {(() => {
                              if (col.key === 'name') {
                                return (
                                  <Link
                                    className="cursor-pointer hover:text-[--colors-primary-default] duration-200 font-semibold"
                                    to={ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL}
                                    options={{
                                      params: { dashboardUserId: data.id }
                                    }}
                                  >
                                    <p>{data.name}</p>
                                  </Link>
                                )
                              }

                              if (['type', 'role'].includes(col.key))
                                return capitalize(data[col.key])

                              return data[col.key] ?? 'N/A'
                            })()}
                          </td>
                        ))}
                      </tr>
                    ))}

                    {!list.length && (
                      <tr className={tableRow}>
                        <td
                          colSpan={columns.length + 2}
                          style={{ textAlign: 'center' }}
                          className={customTableCell}
                        >
                          NO DATA
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </main>
          </article>

          {fetchingGlobalDashboardUserList && <Loading />}

          {!!list.length && (
            <footer className="sticky bottom-0 bg-[--colors-area-high] p-4">
              <Pager align="right" {...pagerProps} />
            </footer>
          )}
        </article>
      </section> */}
    </>
  )
}

export default GlobalDashboardUser
