import { ROUTE_NAMES } from '$router/config'
import {
  FolderOpen,
  LucideIcon,
  Settings,
  UserCog2,
  Users2
} from 'lucide-react'

export const MainSidebarMenu: {
  name: string
  to: ROUTE_NAMES
  Icon: LucideIcon
  subPath: ROUTE_NAMES[]
}[] = [
  {
    name: 'Applications',
    to: ROUTE_NAMES.APPS,
    Icon: FolderOpen,
    subPath: [
      ROUTE_NAMES.APP_HOME,
      ROUTE_NAMES.APP_BASIC_SETTING,
      ROUTE_NAMES.APP_ADVANCED_SETTING,
      ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
      ROUTE_NAMES.APP_TOKEN_SETTING,
      ROUTE_NAMES.APP_GROUPS,
      ROUTE_NAMES.APP_GROUP_DETAIL,
      ROUTE_NAMES.APP_GROUP_CREATE,
      ROUTE_NAMES.APP_GROUP_EDIT,
      ROUTE_NAMES.APP_ROLES,
      ROUTE_NAMES.APP_ROLE_DETAIL,
      ROUTE_NAMES.APP_ROLE_CREATE,
      ROUTE_NAMES.APP_ROLE_EDIT,
      ROUTE_NAMES.APP_USERS,
      ROUTE_NAMES.APP_USER_DETAIL,
      ROUTE_NAMES.APP_USER_CREATE,
      ROUTE_NAMES.APP_USER_EDIT,
      ROUTE_NAMES.APP_PERMISSIONS,
      ROUTE_NAMES.APP_PERMISSION_DETAIL,
      ROUTE_NAMES.APP_PERMISSION_CREATE,
      ROUTE_NAMES.APP_PERMISSION_EDIT,
      ROUTE_NAMES.APP_ROLES_CSV_IMPORT,
      ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT,
      ROUTE_NAMES.APP_USERS_CSV_IMPORT
    ]
  },
  {
    name: 'Application Users',
    to: ROUTE_NAMES.GLOBAL_APP_USERS,
    Icon: Users2,
    subPath: [
      ROUTE_NAMES.GLOBAL_APP_USER_DETAIL,
      ROUTE_NAMES.GLOBAL_APP_USER_ATTACH_APP
    ]
  },
  {
    name: 'Dashboard Users',
    to: ROUTE_NAMES.GLOBAL_DASHBOARD_USERS,
    Icon: UserCog2,
    subPath: [
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_CREATE,
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL,
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_ATTACH_APP
    ]
  },
  {
    name: 'Global Setting',
    to: ROUTE_NAMES.GLOBAL_SETTING,
    Icon: Settings,
    subPath: [
      ROUTE_NAMES.COUNTRIES,
      ROUTE_NAMES.COUNTRY_CREATE,
      ROUTE_NAMES.COUNTRY_EDIT,
      ROUTE_NAMES.PASSWORD_POLICY
    ]
  }
]
