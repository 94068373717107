import {
  getAppsToAssign,
  getGlobalDashboardUserDetail,
  getGlobalDashboardUserList,
  getUserRoleSelect,
  getUserTypeSelect,
  postAppsToAssign,
  postGlobalDashboardUserDetail,
  putGlobalDashboardUserDetail
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useGlobalDashboardUserService = () => {
  const {
    run: fetchGlobalDashboardUserList,
    runAsync: fetchGlobalDashboardUserListAsync,
    data: GlobalDashboardUserListData,
    loading: fetchingGlobalDashboardUserList
  } = useRequest(getGlobalDashboardUserList, {
    manual: true
  })

  const {
    run: fetchGlobalDashboardUserDetail,
    runAsync: fetchGlobalDashboardUserDetailAsync,
    data: globalDashboardUserDetail,
    loading: fetchingGlobalDashboardUserDetail
  } = useRequest(getGlobalDashboardUserDetail, {
    manual: true
  })

  const { data: userRoleSelectData, run: fetchUserRoleSelect } = useRequest(
    getUserRoleSelect,
    { manual: true }
  )

  const { data: userTypeSelectData, run: fetchUserTypeSelect } = useRequest(
    getUserTypeSelect,
    { manual: true }
  )

  const {
    runAsync: createGlobalDashboardUserAsync,
    loading: creatingGlobalDashboardUser
  } = useRequest(postGlobalDashboardUserDetail, {
    manual: true,
    onSuccess: () => {
      toast.success('Successfully Created!')
    }
  })

  const {
    runAsync: updateGlobalDashboardUserAsync,
    loading: updatingGlobalDashboardUser
  } = useRequest(putGlobalDashboardUserDetail, {
    manual: true,
    onSuccess: (_, [userId]) => {
      toast.success('Successfully Updated!')
      fetchGlobalDashboardUserDetail(userId)
    }
  })

  const {
    run: fetchAppsToAssign,
    loading: fetchingAppsToAssign,
    data: appsToAssignData
  } = useRequest(getAppsToAssign, { manual: true })

  const { runAsync: attachAppsToUserAsync, loading: attachingAppsToUser } =
    useRequest(postAppsToAssign, { manual: true })

  const { data: list = [], meta } = { ...GlobalDashboardUserListData }

  const { data: appsToAssignList = [], meta: appsToAssignMeta } =
    appsToAssignData ?? {}

  const user_roles_options =
    userRoleSelectData?.data.map(v => ({
      label: v.display_name,
      value: v.name
    })) ?? []

  const user_types_options =
    userTypeSelectData?.data.map(v => ({
      label: v.display_name,
      value: v.name
    })) ?? []

  return {
    fetchGlobalDashboardUserList,
    fetchGlobalDashboardUserListAsync,
    fetchingGlobalDashboardUserList,
    dashboardUsers: { list, meta },

    fetchGlobalDashboardUserDetail,
    fetchGlobalDashboardUserDetailAsync,
    dashboardUser: globalDashboardUserDetail?.data,
    fetchingGlobalDashboardUserDetail,

    fetchUserRoleSelect,
    fetchUserTypeSelect,
    user_roles_options,
    user_types_options,

    updateGlobalDashboardUserAsync,
    updatingGlobalDashboardUser,

    createGlobalDashboardUserAsync,
    creatingGlobalDashboardUser,

    appsToAssign: { list: appsToAssignList, meta: appsToAssignMeta },
    fetchingAppsToAssign,
    fetchAppsToAssign,

    attachAppsToUserAsync,
    attachingAppsToUser
  }
}
