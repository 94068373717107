import AttachAppToUser from '$blocks/AttachAppToUser'
import RedirectPrompt from '$blocks/RedirectPrompt'
import Breadcrumb from '$components/Breadcrumb/v2'
import FooterAction from '$components/FooterAction'
import Loading from '$components/Loading'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { useGlobalDashboardUserService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { Avatar, Button, Spinner } from '@genie-fintech/ui/components'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useCallback } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { ACCOUNT_TYPE } from '../constants'
import DashboardAdminMessage from '$components/DashboardAdminMessage'
import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

const schema = z.object({
  applications: z.number().array()
})

type TFormValues = z.infer<typeof schema>

const GlobalDashboardAddApplication = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { dashboardUserId } = params

  const proxyRedirect = useRedirectProxy()

  const {
    attachAppsToUserAsync,
    attachingAppsToUser,
    dashboardUser,
    fetchingGlobalDashboardUserDetail,
    fetchGlobalDashboardUserDetailAsync
  } = useGlobalDashboardUserService()

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      applications: []
    }
  })

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset
  } = methods

  useMount(() => {
    if (!dashboardUserId) return
    fetchGlobalDashboardUserDetailAsync(dashboardUserId).then(({ data }) => {
      const appIds = data.applications.map(v => v.id)
      reset({ applications: appIds as never })
    })
  })

  const { replace } = useFieldArray({ name: 'applications' as never, control })

  const applications = useWatch({ name: 'applications', control })

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL, { params })
  }, [params])

  const onSubmit = handleSubmit((payload: TFormValues) => {
    if (!dashboardUserId) return
    attachAppsToUserAsync(dashboardUserId, payload).then(onSuccess)
  })

  if (fetchingGlobalDashboardUserDetail) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.GLOBAL_DASHBOARD_USERS}
        data={[{ name: 'User Details' }]}
      />

      <form className="flex w-full max-w-[1056px] mx-auto" onSubmit={onSubmit}>
        {dashboardUser?.type !== ACCOUNT_TYPE.ADMIN && (
          <AttachAppToUser applications={applications} onChange={replace} />
        )}

        {dashboardUser?.type === ACCOUNT_TYPE.ADMIN && (
          <article
            className={cn(
              'flex justify-center rounded-lg px-12 py-7 w-full',
              defaultBackground,
              defaultBorder
            )}
          >
            <article className="flex flex-col items-center px-6 py-4 gap-6">
              <DashboardAdminMessage />

              <article className="inline-flex gap-2 items-center">
                <Avatar size={32} />

                <article>
                  <article className="inline-flex gap-1.5">
                    <p
                      className="font-medium text-sm"
                      style={{ color: colors.text.light }}
                    >
                      {dashboardUser.name}
                    </p>

                    <span
                      className="inline-flex justify-center items-center px-1.5 py-px rounded text-xs font-medium"
                      style={{
                        background: colors.alphaSuccess[1],
                        color: colors.success[markedDefaultKey]
                      }}
                    >
                      Admin
                    </span>
                  </article>

                  <p className="text-xs" style={{ color: colors.neutral[60] }}>
                    {dashboardUser.email}
                  </p>
                </article>
              </article>
            </article>
          </article>
        )}

        <FooterAction>
          <article className="flex items-center justify-end gap-2 max-w-[1056px] mx-auto">
            <Button
              styleVariants={{ type: 'text' }}
              disabled={attachingAppsToUser}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button type="submit" disabled={!isDirty || attachingAppsToUser}>
              {attachingAppsToUser && <Spinner />}
              Save Changes
            </Button>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt
        type="edit"
        isDirty={isDirty}
        isValid={isValid}
        onConfirm={onSubmit}
        loading={attachingAppsToUser}
      />
    </>
  )
}

export default GlobalDashboardAddApplication
