import { cn } from '$app/utils'
import { APP_STATUS } from '$components/PublishUnpublishButton/types'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { CSSProperties } from 'react'

const { colors } = themeVars

const styles: Record<APP_STATUS, CSSProperties> = {
  [APP_STATUS.PUBLISH]: {
    color: colors.success[markedDefaultKey],
    background: colors.alphaSuccess[1]
  },
  [APP_STATUS.UNPUBLISH]: {
    color: colors.neutral[70],
    background: colors.alphaNeutral[1]
  }
}

const PublishUnpublishBadge = ({ status }: { status: APP_STATUS }) => {
  const text = (() => {
    if (status === APP_STATUS.PUBLISH) return 'PUBLISHED'
    return 'UNPUBLISHED'
  })()

  return (
    <article>
      <span
        style={styles[status]}
        className={cn(
          'text-xs font-medium inline-flex items-center gap-x-1 px-1.5 py-px rounded'
        )}
      >
        {text}
      </span>
    </article>
  )
}

export default PublishUnpublishBadge
