import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppTokenService } from '$hooks/services'
import { TAppTokenSetting, TAppTokenSettingPayload } from '$services/api'
import { useMount } from 'ahooks'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import { BaseText } from '@genie-fintech/ui/components/fields'
import Switch from '$components/Switch'
import { TokenIcon } from '$assets/svg'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'
import Breadcrumb from '$components/Breadcrumb/v2'
import FooterAction from '$components/FooterAction'
import RedirectPrompt from '$blocks/RedirectPrompt'
import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'

const DIGIT_REGEX = /^\d+$/

const schema = z
  .object({
    refresh_token_expiration: z.boolean(),
    refresh_token_lifespan: z
      .string()
      .trim()
      .refine(val => DIGIT_REGEX.test(val), 'Invalid Number!')
      .or(z.literal('')),
    refresh_token_inactive_expiration: z.boolean(),
    refresh_token_inactive_lifespan: z
      .string()
      .trim()
      .refine(val => DIGIT_REGEX.test(val), 'Invalid Number!')
      .or(z.literal('')),
    access_token_lifespan: z
      .string()
      .trim()
      .refine(val => DIGIT_REGEX.test(val), 'Invalid Number!')
      .or(z.literal('')),
    id_token_lifespan: z
      .string()
      .trim()
      .refine(val => DIGIT_REGEX.test(val), 'Invalid Number!')
      .or(z.literal(''))
  })
  .superRefine((val, ctx) => {
    if (val.refresh_token_expiration && !val.refresh_token_lifespan.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['refresh_token_lifespan'],
        message: `Required!`
      })
    }

    if (
      val.refresh_token_inactive_expiration &&
      !val.refresh_token_inactive_lifespan.length
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['refresh_token_inactive_lifespan'],
        message: `Required!`
      })
    }
  })

type TFormValues = z.infer<typeof schema>

const AppTokenSetting = () => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema)
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, isValid }
  } = methods

  const {
    fetchAppTokenSettingAsync,
    fetchingAppTokenSetting,
    updateAppTokenSettingAsync,
    updatingAppTokenSetting
  } = useAppTokenService()

  const transformToFormValues = useCallback(
    (data: TAppTokenSetting): TFormValues => {
      return {
        refresh_token_expiration: !!data.refresh_token_expiration,
        refresh_token_lifespan: data.refresh_token_lifespan
          ? `${data.refresh_token_lifespan}`
          : '',
        refresh_token_inactive_expiration:
          !!data.refresh_token_inactive_expiration,
        refresh_token_inactive_lifespan: data.refresh_token_inactive_lifespan
          ? `${data.refresh_token_inactive_lifespan}`
          : '',
        access_token_lifespan: data.access_token_lifespan
          ? `${data.access_token_lifespan}`
          : '',
        id_token_lifespan: data.id_token_lifespan
          ? `${data.id_token_lifespan}`
          : ''
      }
    },
    []
  )

  useMount(() => {
    if (!appId) return
    fetchAppTokenSettingAsync({ application_id: appId }).then(({ data }) => {
      reset(transformToFormValues(data))
    })
  })

  const refresh_token_expiration_value = useWatch({
    name: 'refresh_token_expiration',
    control
  })

  const refresh_token_inactive_expiration_value = useWatch({
    name: 'refresh_token_inactive_expiration',
    control
  })

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId) return

    const payload: TAppTokenSettingPayload = {
      refresh_token_expiration: formValues.refresh_token_expiration,
      refresh_token_lifespan: formValues.refresh_token_lifespan
        ? +formValues.refresh_token_lifespan
        : null,
      refresh_token_inactive_expiration:
        formValues.refresh_token_inactive_expiration,
      refresh_token_inactive_lifespan:
        formValues.refresh_token_inactive_lifespan
          ? +formValues.refresh_token_inactive_lifespan
          : null,
      access_token_lifespan: formValues.access_token_lifespan
        ? +formValues.access_token_lifespan
        : null,
      id_token_lifespan: formValues.id_token_lifespan
        ? +formValues.id_token_lifespan
        : null
    }

    updateAppTokenSettingAsync(appId, payload).then(() => {
      fetchAppTokenSettingAsync({ application_id: appId }).then(({ data }) => {
        reset(transformToFormValues(data))
      })
    })
  })

  const isProcessing = fetchingAppTokenSetting || updatingAppTokenSetting

  const app_name = useAppDetailStore(state => state.appDetail?.name) ?? ''

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <form
        className={cn(
          'flex-1 flex flex-col relative rounded-lg',
          defaultBackground,
          defaultBorder
        )}
        onSubmit={onSubmit}
      >
        <header className="flex items-center justify-between px-4 py-3 border-b border-[--colors-neutral-10]">
          <article className="flex items-center gap-x-2 text-[--colors-text-light]">
            <TokenIcon />
            <p className="text-xl font-semibold">Token</p>
          </article>
        </header>
        <main className="flex-1 grid p-3 lg:grid-cols-[40%_60%]">
          <article className="flex max-w-[400px] flex-col gap-y-1 px-5 mx-auto">
            <p className="text-xl font-semibold">{app_name}'s TOKEN</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Manage secure authentication and access with system tokens.
            </p>
          </article>

          <article className="max-w-[400px] flex flex-col gap-y-4">
            <Controller
              name="refresh_token_expiration"
              control={control}
              render={({ field }) => {
                return (
                  <article className="flex items-center justify-between gap-x-1 border border-[--colors-neutral-10] rounded p-3">
                    <label className="text-sm font-medium">
                      Refresh Token Expiration
                    </label>

                    <Switch checked={field.value} onChange={field.onChange} />
                  </article>
                )
              }}
            />

            <Controller
              name="refresh_token_lifespan"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <BaseText
                    label="Refresh Token Lifespan"
                    required={refresh_token_expiration_value}
                    disabled={!refresh_token_expiration_value}
                    affix={{ post: 'seconds' }}
                    error={!!error?.message}
                    message={error?.message}
                    inputProps={{
                      value: field.value ?? '',
                      onChange: field.onChange
                    }}
                  />
                )
              }}
            />

            <hr className="border-[--colors-neutral-10]" />

            <Controller
              name="refresh_token_inactive_expiration"
              control={control}
              render={({ field }) => {
                return (
                  <article className="flex items-center justify-between gap-x-1 border border-[--colors-neutral-10] rounded p-3">
                    <label className="text-sm font-medium">
                      Refresh Token Inactive Expiration
                    </label>

                    <Switch checked={field.value} onChange={field.onChange} />
                  </article>
                )
              }}
            />

            <Controller
              name="refresh_token_inactive_lifespan"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <BaseText
                    label="Refresh Token Inactive Lifespan"
                    required={refresh_token_inactive_expiration_value}
                    disabled={!refresh_token_inactive_expiration_value}
                    affix={{ post: 'seconds' }}
                    error={!!error?.message}
                    message={error?.message}
                    inputProps={{
                      value: field.value ?? '',
                      onChange: field.onChange
                    }}
                  />
                )
              }}
            />

            <hr className="border-[--colors-neutral-10]" />

            <BaseHookFieldText
              name="access_token_lifespan"
              control={control}
              label="Access Token Lifespan"
              affix={{ post: 'seconds' }}
            />

            <BaseHookFieldText
              name="id_token_lifespan"
              control={control}
              label="ID Token Lifespan"
              affix={{ post: 'seconds' }}
            />
          </article>
        </main>

        <FooterAction>
          <SaveWithShortCutButton
            disabled={!isDirty || isProcessing}
            loading={updatingAppTokenSetting}
          />
        </FooterAction>
      </form>

      <RedirectPrompt
        isDirty={isDirty}
        isValid={isValid}
        loading={isProcessing}
        onConfirm={onSubmit}
        type="edit"
      />
    </>
  )
}

export default AppTokenSetting
