import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { AlertTriangle } from 'lucide-react'

const { colors } = themeVars

const WarningIcon = () => {
  return (
    <article
      className="inline-flex justify-center items-center w-10 h-10 rounded-lg"
      style={{
        background: colors.alphaWarning[1],
        border: `1px solid ${colors.warning[20]}`,
        color: colors.warning[markedDefaultKey]
      }}
    >
      <AlertTriangle size={20} />
    </article>
  )
}

export default WarningIcon
