import { useRoute } from '$contexts/RouteContext/hooks'
import {
  buildURL,
  getDetail,
  RedirectOptions,
  ROUTE_NAMES
} from '$router/config'
import { FC, PropsWithChildren } from 'react'

interface IProps {
  name: ROUTE_NAMES
  options?: RedirectOptions
}

const NewTabRedirect: FC<PropsWithChildren<IProps>> = ({
  children,
  name,
  options
}) => {
  const currentRoute = useRoute()

  const redirectOptions = (() => {
    if (typeof options == 'function') {
      const { params, hash, queryParams } = currentRoute

      return options({
        hash,
        params,
        queryParams
      })
    }

    return options
  })()

  const { pathname } = getDetail(name)

  const href = buildURL(pathname, redirectOptions).toString()

  return (
    <a href={href} target="_blank">
      {children}
    </a>
  )
}

export default NewTabRedirect
