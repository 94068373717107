import { cn } from '$app/utils'
import Breadcrumb from '$components/Breadcrumb/v2'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useCallback, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppPermissionService } from '$hooks/services'
import { TAppPermissionPayload, TID } from '$services/api'
import FooterAction from '$components/FooterAction'
import { Button, Spinner } from '@genie-fintech/ui/components'
import RedirectPrompt from '$blocks/RedirectPrompt'
import PermissionRoles from '../Detail/PermissionRoles'

const { colors } = themeVars

const AppPermissionCreate = () => {
  const [step, setStep] = useState(1)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const proxyRedirect = useRedirectProxy()

  const { savePermissionAsync, savingPermission } = useAppPermissionService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isDirty }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_PERMISSIONS, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(
    (permissionId: TID) => {
      redirect(ROUTE_NAMES.APP_PERMISSION_DETAIL, {
        params: { ...params, permissionId }
      })
    },
    [params]
  )

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId) return

    const payload: TAppPermissionPayload = formValues

    return savePermissionAsync(appId, payload).then(({ data }) => {
      onSuccess(data.id)
    })
  })

  const onBack = useCallback(() => {
    setStep(prev => prev - 1)
  }, [])

  const onNext = useCallback(async () => {
    const isValid = await trigger(['name', 'module_name', 'description'])
    if (isValid) setStep(prev => prev + 1)
  }, [trigger])

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: `App Details`, path: ROUTE_NAMES.APP_PERMISSIONS },
          { name: `Add Permission` }
        ]}
      />

      <form
        className="flex flex-col max-w-[1056px] mx-auto gap-2"
        onSubmit={onSubmit}
      >
        {step === 1 && (
          <article
            className={cn(
              'flex flex-col rounded-lg',
              defaultBackground,
              defaultBorder
            )}
          >
            <header
              className="flex items-center px-12 py-3 border-b"
              style={{ borderColor: colors.neutral[10] }}
            >
              <p
                className="text-xl font-semibold"
                style={{ color: colors.text.light }}
              >
                Add New Permission
              </p>
            </header>

            <article className="flex px-12 py-7">
              <article className="grid lg:grid-cols-2 gap-8">
                <article className="flex flex-col gap-1">
                  <p className="font-semibold">PERMISSION INFO</p>
                  <p className="text-xs" style={{ color: colors.neutral[50] }}>
                    Review detailed permissions to manage user access and
                    functionality within the system.
                  </p>
                </article>

                <article className="flex flex-col gap-5">
                  <BaseText
                    control={control}
                    name="name"
                    label="Permission Name"
                    required
                  />

                  <BaseText
                    control={control}
                    name="module_name"
                    label="Module Name"
                    required
                  />

                  <Textarea
                    name="description"
                    control={control}
                    label="Description"
                    required
                  />
                </article>
              </article>
            </article>
          </article>
        )}

        {step === 2 && (
          <PermissionRoles selectedRoles={selectedRoles} onChange={replace} />
        )}

        <FooterAction>
          <article className="flex w-full max-w-[1056px] gap-2 mx-auto">
            {step === 2 && (
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                disabled={savingPermission}
                onClick={onBack}
              >
                Back
              </Button>
            )}
            <article className="flex-1 flex justify-end gap-2">
              <Button
                disabled={savingPermission}
                styleVariants={{ type: 'text' }}
                onClick={onCancel}
              >
                Cancel
              </Button>

              {step === 1 && (
                <Button disabled={!isDirty} onClick={onNext}>
                  Next
                </Button>
              )}

              {step === 2 && (
                <Button type="submit" disabled={savingPermission}>
                  {savingPermission && <Spinner />}
                  Create
                </Button>
              )}
            </article>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt isDirty={isDirty} type="create" />
    </>
  )
}

export default AppPermissionCreate
