import { APP_BRANDS } from '$app/constants'
import { useCallback, useMemo, useState } from 'react'
import {
  Container,
  containerBackgroundColor,
  containerSize,
  imageSize,
  ImageStyle,
  Indicator
} from './styles.css'
import { Size } from './types'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { SIZE } from './constants'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { trueOrUndefined } from '@genie-fintech/ui/functions'

const { colors } = themeVars

const { container, image } = SIZE

interface IProps {
  imgUrl?: string
  brand?: string
  isPublished?: boolean
  size?: Size
}

const AppLogo = ({
  imgUrl,
  brand,
  isPublished = true,
  size = 'small'
}: IProps) => {
  const [imageLoadError, setImageLoadError] = useState(false)

  const [loading, setLoading] = useState(true)

  const { Icon: brandIcon = '', backgroundColor: brandBGColor = '' } =
    APP_BRANDS.find(d => d.name === brand) ?? {}

  const onImageLoadError = useCallback(() => {
    setImageLoadError(true)
  }, [])

  const onImageLoad = useCallback(() => {
    setLoading(false)
  }, [])

  const url = useMemo(() => {
    const _url = imageLoadError ? brandIcon : imgUrl
    return _url ?? ''
  }, [imageLoadError, brandIcon, imgUrl])

  const background = useMemo(() => {
    if (imageLoadError) return brandBGColor
    return colors.area.disabled
  }, [imageLoadError, brandBGColor])

  return (
    <article
      className={Container}
      style={assignInlineVars({
        [containerSize]: `${container[size]}px`,
        [containerBackgroundColor]: background
      })}
    >
      <img
        src={url}
        data-loading={trueOrUndefined(loading)}
        className={ImageStyle}
        style={{ ...assignInlineVars({ [imageSize]: `${image[size]}px` }) }}
        onError={onImageLoadError}
        onLoad={onImageLoad}
      />

      {isPublished && (
        <span className={Indicator}>
          <span />
        </span>
      )}
    </article>
  )
}

export default AppLogo
