import CSVImport from '$blocks/CSVImport'

const AppUserCSVImport = () => {
  return (
    <article>
      <CSVImport importType="user" />
    </article>
  )
}

export default AppUserCSVImport
