import { APP_ENVIRONMENT_OPTIONS } from '$app/constants'
import { cn } from '$app/utils'
import AppEnvironment from '$components/AppEnvironment'
import AppLogo from '$components/AppLogo'
import EmptyData from '$components/EmptyData'
import Loading from '$components/Loading'
import Pagination from '$components/Pagination'
import { useApiListingParams } from '$hooks/actions'
import { useGlobalDashboardUserService } from '$hooks/services'
import { card, defaultBackground, defaultBorder } from '$styles/common.css'
import { Checkbox, Tabs } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useEffect, useState } from 'react'

const { colors } = themeVars

interface IProps {
  applications: number[]
  onChange: (applications: number[]) => void
}

const AttachAppToUser = ({ applications, onChange }: IProps) => {
  const [appEnvId, setEnvId] = useState('')

  const {
    fetchAppsToAssign,
    fetchingAppsToAssign,
    appsToAssign: { list, meta }
  } = useGlobalDashboardUserService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    fetchAppsToAssign({
      q: debouncedSearchValue,
      per_page: perPage,
      page,
      app_env: appEnvId
    })
  }, [fetchAppsToAssign, debouncedSearchValue, perPage, page, appEnvId])

  return (
    <article
      className={cn(
        'flex flex-col px-12 py-7 rounded-lg gap-6 w-full',
        defaultBackground,
        defaultBorder
      )}
    >
      <header className="flex flex-col gap-1">
        <p className="font-semibold">CHOOSE ACCESS APPLICATIONS TO MANAGE</p>
        <p className="text-xs" style={{ color: colors.neutral[50] }}>
          Select applications to define access levels and permissions for users.
        </p>
      </header>

      <article className="flex flex-col gap-4">
        <article className="flex items-center justify-between gap-1">
          <article>
            <BaseText
              affix={{
                pre: <Icon namespace="Search" width={16} />,
                post: searchValue ? (
                  <button onClick={() => updateSearchValue('')}>
                    <Icon namespace="Cross" width={18} />
                  </button>
                ) : undefined
              }}
              inputProps={{
                type: 'text',
                value: searchValue,
                onChange: e => updateSearchValue(e.currentTarget.value),
                placeholder: 'Search here...',
                className: 'min-w-[200px]'
              }}
            />
          </article>

          <p
            className="text-sm font-medium"
            style={{ color: colors.text.disabled }}
          >
            {list.length} APPLICATIONS
          </p>
        </article>

        <article>
          <Tabs.Root
            value={appEnvId}
            onValueChange={setEnvId}
            format={{ type: 'segmented' }}
          >
            <Tabs.List styleVariants={{ hAlign: 'left' }}>
              {[
                { label: 'All', value: '' },
                ...APP_ENVIRONMENT_OPTIONS.map(v => ({
                  label: v.value,
                  value: v.key + ''
                }))
              ].map((v, k) => (
                <Tabs.Trigger key={k} value={`${v.value}`}>
                  <span className="uppercase">{v.label}</span>
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          </Tabs.Root>
        </article>

        {fetchingAppsToAssign && <Loading />}

        <article className="grid md:grid-cols-2 gap-5 py-5">
          {list.map((v, k) => {
            return (
              <article key={k} className="flex gap-5 items-center">
                <Checkbox
                  boxProps={{
                    checked: applications.includes(v.id),
                    onCheckedChange: checked => {
                      if (checked) {
                        onChange([...applications, v.id])
                        return
                      }

                      onChange(applications.filter(d => d !== v.id))
                    }
                  }}
                />

                <article
                  className={cn(
                    'flex items-center rounded-lg p-4 gap-4 flex-1',
                    card
                  )}
                >
                  <AppLogo {...v} size="big" />
                  <article className="flex flex-col gap-1">
                    <p className="text-sm font-medium">{v.name}</p>
                    <AppEnvironment environment={v.environment} />
                  </article>
                </article>
              </article>
            )
          })}
        </article>

        {!list.length && !fetchingAppsToAssign && (
          <article className="flex-1 grid place-items-center">
            <EmptyData type="application" />
          </article>
        )}

        {!!list.length && (
          <footer className="flex justify-between items-center bg-[--colors-area-high] px-12 py-4">
            <Pagination meta={meta} pagerProps={pagerProps} />
          </footer>
        )}
      </article>
    </article>
  )
}

export default AttachAppToUser
