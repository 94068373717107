import { toast } from 'sonner'
import { ALLOWED_FILE_TYPES } from './constants'

export const getFileNameWithExt = (fileName: string, fileType: string) => {
  let result = fileName

  const ext = ALLOWED_FILE_TYPES.find(d => d.type === fileType)?.name

  if (ext) result += `.${ext}`

  return result
}

export const validateFile = (file: File) => {
  if (!file) return false

  const isValid = ALLOWED_FILE_TYPES.some(v => v.type === file.type)

  if (!isValid) {
    toast.error('Invalid File Type!')
    return false
  }

  return true
}
