import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { RouterProvider } from '$contexts/RouteContext/RouteProvider.tsx'

// react-modern-drawer css
import 'react-modern-drawer/dist/index.css'
import 'unfonts.css'

import '$styles/index.css'

// Genie UI global css
import '@genie-fintech/ui/style/global/attach-variables-aliases.css'
import '@genie-fintech/ui/style/global'
import '$styles/app.css.ts'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider>
      <App />
    </RouterProvider>
  </React.StrictMode>
)
