import { APP_STATUS } from './types'

export const INFO: Record<APP_STATUS, { title: string; desc: string }> = {
  [APP_STATUS.PUBLISH]: {
    title: `Are you sure, you want to publish the application?`,
    desc: `The application will be published. Application users can be seen or used.`
  },
  [APP_STATUS.UNPUBLISH]: {
    title: `Are you sure, you want to unpublish the application?`,
    desc: `The application will be unpublished. Application users can not be seen or used.`
  }
}
