import { cn } from '$app/utils'
import { card, shadow } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { format } from 'date-fns'

const { colors } = themeVars

const now = new Date()

const TodayCard = () => {
  return (
    <article style={{ display: 'inline', width: 72 }}>
      <article
        className={cn('flex flex-col overflow-hidden', card, shadow.small)}
      >
        <p
          className="inline-flex justify-center px-1 py-3 text-sm font-semibold"
          style={{ background: colors.area.low }}
        >
          {format(now, 'E')}
        </p>
        <p
          className="inline-flex justify-center px-1 py-3 text-sm font-medium"
          style={{ color: colors.neutral[60] }}
        >
          {format(now, 'dd')}
        </p>
      </article>
    </article>
  )
}

export default TodayCard
