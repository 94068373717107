import CSVImport from '$blocks/CSVImport'

const AppRoleCSVImport = () => {
  return (
    <article>
      <CSVImport importType="role" />
    </article>
  )
}

export default AppRoleCSVImport
