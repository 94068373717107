import { getFlagUrl } from '$app/utils'
import Breadcrumb from '$components/Breadcrumb/v2'
import { useCountryStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { TCountry } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { Globe2 } from 'lucide-react'
import { useCallback } from 'react'

const columns: { key: keyof TCountry; value: string }[] = [
  { key: 'id', value: 'NO' },
  { key: 'name', value: 'COUNTRY NAME' },
  { key: 'country_code', value: 'COUNTRY CODE' },
  { key: 'phone_code', value: 'PHONE CODE' },
  { key: 'timezone', value: 'TIMEZONE' },
  { key: 'currency_name', value: 'CURRENCY NAME' },
  { key: 'currency_code', value: 'CODE' },
  { key: 'currency_symbol', value: 'SYMBOL' }
]
const CountryList = () => {
  const countries = useCountryStore(state => state.countries)

  const handleOnClickAddNew = useCallback(() => {
    redirect(ROUTE_NAMES.COUNTRY_CREATE)
  }, [])

  return (
    <>
      <Breadcrumb category={ROUTE_NAMES.GLOBAL_SETTING} />

      <article className="flex flex-col gap-4 relative bg-[--colors-area-high] rounded-lg overflow-hidden">
        <header className="flex items-center justify-between h-[56px] px-3 bg-[--colors-area-high] border-b border-[--colors-neutral-10]">
          <article className="flex items-center gap-2 text-[--colors-text-light]">
            <Globe2 />
            <p className="text-xl font-semibold">Countries</p>
          </article>
          <Button onClick={handleOnClickAddNew}>
            <Icon namespace="Add" />
            Add New
          </Button>
        </header>

        <article className="px-5">
          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className={tableBody}>
                  {countries.map((data, rowKey) => (
                    <tr key={rowKey} className={tableRow}>
                      {columns.map((col, colKey) => (
                        <td key={colKey} className={customTableCell}>
                          {(() => {
                            if (col.key === 'id') {
                              return rowKey + 1
                            }

                            if (col.key === 'name') {
                              return (
                                <Link
                                  to={ROUTE_NAMES.COUNTRY_EDIT}
                                  options={{ params: { countryId: data.id } }}
                                  className="inline-flex gap-2 items-center hover:text-[--colors-primary-default]"
                                >
                                  <article className="w-5 h-5 rounded-full overflow-hidden border border-[--colors-neutral-10]">
                                    <img
                                      src={getFlagUrl(data.country_code)}
                                      className="rounded-full w-full h-full object-cover"
                                    />
                                  </article>
                                  <span>{data.name}</span>
                                </Link>
                              )
                            }

                            return data[col.key] ?? 'N/A'
                          })()}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {!countries.length && (
                    <tr className={tableRow}>
                      <td
                        colSpan={columns.length}
                        style={{ textAlign: 'center' }}
                        className={customTableCell}
                      >
                        NO DATA
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </main>
          </article>
        </article>
      </article>
    </>
  )
}

export default CountryList
