import { TAppListResponse } from '$services/api'
import { CopyButton } from '$components/CopyButon'
import AppLogo from '$components/AppLogo'
import { redirect, ROUTE_NAMES } from '$router/config'
import { APP_LAUNCHPAD_URL } from '$services/environments'

import AppEnvironment from '$components/AppEnvironment'
import { useCallback } from 'react'
import { TOOLTIP_ID } from '$app/constants'
import { Folders, Link, ListTree, LucideIcon, Users2 } from 'lucide-react'
import { cn, formatNumber } from '$app/utils'
import { card, ready } from '$styles/common.css'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { capitalize } from 'lodash-es'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars
interface IAppCardProps {
  data: TAppListResponse['data'][number]
}

type ItemType = 'user' | 'group' | 'role'

const IconCount = ({
  type,
  count,
  iconName: Icon
}: {
  type: ItemType
  count: number
  iconName: LucideIcon
}) => {
  return (
    <article
      className="inline-flex gap-1 items-center"
      data-tooltip-id={TOOLTIP_ID}
      data-tooltip-content={capitalize(type + 's')}
    >
      <Icon size={16} style={{ color: colors.text.disabled }} />
      <span
        className="text-sm font-medium"
        style={{ color: colors.text.light }}
      >
        {formatNumber(count)}
      </span>
    </article>
  )
}

const AppCard = ({ data }: IAppCardProps) => {
  const {
    id: appId,
    logo,
    brand,
    is_published,
    name,
    environment,
    client_id,
    user_count,
    group_count,
    role_count
  } = data

  const isMounted = useIsMounted()

  const handleOnClickCard = useCallback(() => {
    redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
  }, [appId])

  return (
    <article
      onClick={handleOnClickCard}
      className={cn(
        'flex flex-col cursor-pointer gap-4 px-4 py-2',
        ready,
        card
      )}
      data-ready={trueOrUndefined(isMounted)}
    >
      <article className="flex gap-x-2 items-center">
        <AppLogo
          imgUrl={logo?.url}
          brand={brand}
          size="big"
          isPublished={is_published}
        />

        <article className="flex flex-col">
          <p className="font-medium text-sm">{name}</p>

          <AppEnvironment environment={environment} />
        </article>
      </article>

      <article className="grid grid-cols-2 rounded-lg border border-[--colors-neutral-10] divide-x divide-[--colors-neutral-10] bg-[--colors-area-high] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1]">
        <CopyButton
          classNames={{
            container: 'flex flex- 1 justify-center gap-x-2 py-2'
          }}
          value={client_id}
          size={14}
        >
          <p className="text-xs font-medium text-[--colors-neutral-70]">
            Client ID
          </p>
        </CopyButton>

        <CopyButton
          classNames={{
            container: 'flex flex- 1 justify-center gap-x-2 py-2'
          }}
          value={APP_LAUNCHPAD_URL}
          size={14}
          initialIcon={Link}
        >
          <p className="text-xs font-medium text-[--colors-neutral-70]">URL</p>
        </CopyButton>
      </article>

      <article className="flex items-center justify-between">
        <IconCount type="user" count={user_count} iconName={Users2} />

        <IconCount type="role" count={role_count} iconName={ListTree} />

        <IconCount type="group" count={group_count} iconName={Folders} />
      </article>
    </article>
  )
}

export default AppCard
