import { TAppHome } from '$services/api'
import { ButtonProps } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { format } from 'date-fns'

const { colors } = themeVars

export const getDailyActiveUserOption = (data: TAppHome['active_users']) => ({
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: 'Daily Active User',
      data: data.map(v => v.count),
      color: colors.primary[70],
      fillColor: colors.alphaPrimary[3]
    }
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
          }
        }
      }
    ]
  },
  xAxis: {
    categories: data.map(v => format(v.date, 'E dd')),
    labels: {
      useHtml: true,
      formatter: ({ value }: { value: string }) => {
        return value.split(' ').join('<br>')
      },
      style: {
        color: colors.text.light
      }
    },
    tickmarkPlacement: 'on',
    plotLines: data.map((_, k) => ({
      dashStyle: 'Dash',
      color: colors.neutral[40],
      width: 1,
      value: k
    }))
  },
  chart: {
    type: 'area',
    backgroundColor: colors.area.high,
    height: 250
  },
  yAxis: {
    title: {
      text: ''
    },
    gridLineWidth: 0,
    labels: {
      enabled: false
    }
  },
  legend: {
    enabled: false
  },
  accessibility: {
    enabled: false
  },
  // Adding the noData module
  noData: {
    style: {
      fontWeight: 'bold',
      color: '#303030'
    },
    useHTML: true,
    position: {
      align: 'center',
      verticalAlign: 'middle',
      x: 0,
      y: 0
    }
  }
})

export const getButtonVariant = (
  cond: boolean
): ButtonProps['styleVariants'] => ({
  kind: cond ? 'primary' : 'neutral',
  type: 'text',
  size: 'small'
})
