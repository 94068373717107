import { BREADCRUMB_BAR_ID } from '$app/constants'
import { cn } from '$app/utils'
import { MainSidebarMenu } from '$components/SideBarToggleMenu/constants'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { dark } from '@genie-fintech/ui/style/theme/colors/sets'
import { createPortal } from 'react-dom'
import { Fragment } from 'react/jsx-runtime'

interface IProps {
  category: (typeof MainSidebarMenu)[number]['to']
  data?: { name: string; path?: ROUTE_NAMES }[]
}

const { colors } = themeVars

const Breadcrumb = ({ category, data = [] }: IProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const isMounted = useIsMounted()

  const { Icon, to, name } = MainSidebarMenu.find(d => d.to === category)!

  const renderBreadcrumbOffset = () => {
    return (
      <article className="flex items-center gap-2 relative pl-2">
        <Link
          to={to}
          options={{ params }}
          className={cn(
            'inline-flex items-center gap-2 text-sm font-medium py-2'
          )}
          style={{ color: colors.absolute.light }}
        >
          <Icon size={20} />

          <span className={cn('font-medium text-sm')}>{name}</span>
        </Link>

        {data.map((v, k) => {
          return (
            <Fragment key={k}>
              <span style={{ color: colors.absolute.light }}> / </span>

              {v.path ? (
                <Link to={v.path} options={{ params }}>
                  <span
                    className={cn('font-medium text-sm cursor-pointer')}
                    style={{ color: colors.absolute.light }}
                  >
                    {v.name}
                  </span>

                  <span className="sr-only">{/* LABEL  */}</span>
                </Link>
              ) : (
                <span
                  className={cn('text-sm font-medium')}
                  style={{ color: dark.colors.neutral[70] }}
                >
                  {v.name}
                </span>
              )}
            </Fragment>
          )
        })}
      </article>
    )
  }

  const targetContainer = document.getElementById(BREADCRUMB_BAR_ID)

  if (!targetContainer || !isMounted) return null

  return createPortal(renderBreadcrumbOffset(), targetContainer)
}

export default Breadcrumb
