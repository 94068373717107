import { redirect, ROUTE_NAMES } from '$router/config'
import {
  deleteApp,
  deleteAppUser,
  deleteGroup,
  deletePermission,
  deleteRole
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useDeleteService = () => {
  const { runAsync: deleteAppAsync, loading: deletingApp } = useRequest(
    deleteApp,
    {
      manual: true,
      onSuccess: () => {
        toast.success('App has been deleted!')
        redirect(ROUTE_NAMES.APPS)
      }
    }
  )

  const { runAsync: deleteGroupAsync, loading: deletingGroup } = useRequest(
    deleteGroup,
    {
      manual: true,
      onSuccess: (_, [{ appId }]) => {
        toast.success('Group has been deleted!')
        redirect(ROUTE_NAMES.APP_GROUPS, { params: { appId } })
      }
    }
  )

  const { runAsync: terminateAppUserAsync, loading: terminatingAppUser } =
    useRequest(deleteAppUser, {
      manual: true,
      onSuccess: (_, [{ appId }]) => {
        toast.success('User has been terminated!')
        redirect(ROUTE_NAMES.APP_USERS, { params: { appId } })
      }
    })

  const { runAsync: deleteRoleAsync, loading: deletingRole } = useRequest(
    deleteRole,
    {
      manual: true,
      onSuccess: (_, [{ appId }]) => {
        toast.success('Role has been deleted!')
        redirect(ROUTE_NAMES.APP_ROLES, { params: { appId } })
      }
    }
  )

  const { runAsync: deletePermissionAsync, loading: deletingPermission } =
    useRequest(deletePermission, {
      manual: true,
      onSuccess: (_, [{ appId }]) => {
        toast.success('Permission has been deleted!')
        redirect(ROUTE_NAMES.APP_PERMISSIONS, { params: { appId } })
      }
    })

  return {
    deleteAppAsync,
    deletingApp,

    deleteGroupAsync,
    deletingGroup,

    terminateAppUserAsync,
    terminatingAppUser,

    deleteRoleAsync,
    deletingRole,

    deletePermissionAsync,
    deletingPermission
  }
}
