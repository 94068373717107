import { cn } from '$app/utils'
import { CopyButton } from '$components/CopyButon'
import DeleteWithConfirm from '$components/DeleteWithConfirm'
import { useAppUserService } from '$hooks/services'
import { TAppUserDetail, TID } from '$services/api'
import { defaultBorder, shadow } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { AlertTriangle, Clock } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'

const { colors } = themeVars

interface IProps {
  reset_link_expired_at: TAppUserDetail['password_reset_link_expired_in']
  appId?: TID
  userId: TID
}

const ONE_HOUR = 60 * 60 // seconds

const format = (value: number) => {
  return `${value}`.padStart(2, '0')
}

const Timer = ({ time }: { time: number }) => {
  if (!time) return null

  const min = format(Math.floor(time / 60) % 60)

  const sec = format(time % 60)

  return (
    <article className="flex items-center text-sm font-medium gap-x-px">
      <span>{min}</span>
      <span>:</span>
      <span>{sec}</span>
    </article>
  )
}

const GenerateResetLink = ({
  reset_link_expired_at,
  appId,
  userId
}: IProps) => {
  const [expiredAt, setExpiredAt] = useState(reset_link_expired_at)

  const [resetLinkURL, setResetLinkURL] = useState('')

  const {
    createResetPwdLinkAsync,
    creatingResetPwdLink,
    removeResetPwdLinkAsync,
    removingResetPwdLink
  } = useAppUserService()

  const onCreateResetPasswordLink = useCallback(() => {
    if (!appId) return

    createResetPwdLinkAsync({ appId, userId }).then(({ data }) => {
      setResetLinkURL(data.url)
      setExpiredAt(ONE_HOUR)
    })
  }, [appId, createResetPwdLinkAsync, userId])

  const onDeleteResetPasswordLink = useCallback(() => {
    if (!appId) return

    removeResetPwdLinkAsync({ appId, userId }).then(() => {
      setExpiredAt(null)
      setResetLinkURL('')
    })
  }, [appId, removeResetPwdLinkAsync, userId])

  useEffect(() => {
    if (!expiredAt) return

    const timerId = setInterval(() => {
      setExpiredAt(prev => (prev ? prev - 1 : prev))
    }, 1000)

    return () => clearInterval(timerId)
  }, [expiredAt])

  return (
    <article className="flex flex-col gap-3">
      {!expiredAt && (
        <Button
          styleVariants={{ type: 'text' }}
          disabled={creatingResetPwdLink}
          onClick={onCreateResetPasswordLink}
        >
          {creatingResetPwdLink && <Spinner />}
          Generate Reset Password Link
        </Button>
      )}

      {expiredAt && (
        <>
          <article
            className={cn(
              'flex rounded-md px-4 py-2 gap-4 min-w-0',
              shadow.small,
              defaultBorder
            )}
          >
            <article
              className="flex flex-col gap-1 justify-center min-w-0 flex-1"
              style={{ color: colors.neutral[70] }}
            >
              <p className="text-xs font-medium">
                {resetLinkURL
                  ? 'Reset Password Link'
                  : 'Generated Reset Password Link'}
              </p>
              {resetLinkURL && (
                <p className="text-[10px] truncate">{resetLinkURL}</p>
              )}
            </article>

            {resetLinkURL && <CopyButton value={resetLinkURL} size={16} />}

            <DeleteWithConfirm
              onConfirm={onDeleteResetPasswordLink}
              loading={removingResetPwdLink}
            />
          </article>

          <article className="flex items-center justify-between gap-1">
            <article
              className="flex gap-1 px-3 py-1.5 items-center"
              style={{ color: colors.text.light }}
            >
              <Clock size={16} />
              <Timer time={expiredAt} />
            </article>

            <article
              className="flex px-3 py-1 gap-1 items-center"
              style={{
                borderRadius: 30,
                color: colors.warning[markedDefaultKey],
                background: colors.alphaWarning[1]
              }}
            >
              <AlertTriangle size={16} />
              <span className="text-xs font-medium">
                Generated link has expiration time.
              </span>
            </article>
          </article>
        </>
      )}
    </article>
  )
}

export default GenerateResetLink
