import { redirect, ROUTE_NAMES } from '$router/config'
import { postUpdatePassword, postUpdateUserStatus } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useUserService = () => {
  const { runAsync: updatePasswordAsync, loading: updatingPassword } =
    useRequest(postUpdatePassword, {
      manual: true,
      onSuccess: () => {
        toast.success('Password Updated Successfully!')
      }
    })

  const { runAsync: updateUserStatusAsync, loading: updatingUserStatus } =
    useRequest(postUpdateUserStatus, {
      manual: true,
      onSuccess: (_, [{ status, isAdmin }]) => {
        const isActive = status === 'active'
        if (!isActive) {
          toast.success('User has been terminated!')
          redirect(
            isAdmin
              ? ROUTE_NAMES.GLOBAL_DASHBOARD_USERS
              : ROUTE_NAMES.GLOBAL_APP_USERS
          )
        }
      }
    })

  return {
    updatePasswordAsync,
    updatingPassword,

    updateUserStatusAsync,
    updatingUserStatus
  }
}
