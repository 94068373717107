import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppRoleService, useDeleteService } from '$hooks/services'
import { useMount } from 'ahooks'
import RoleInfo from './RoleInfo'
import Loading from '$components/Loading'
import RolePermissions from './RolePermissions'
import DangerZone from '$components/DangerZone'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { Button } from '@genie-fintech/ui/components'
import { Trash2 } from 'lucide-react'
import { useCallback } from 'react'

const AppRoleDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { roleId, appId } = params

  const { role, fetchingAppRoleDetail, fetchAppRoleDetailAsync } =
    useAppRoleService()

  const { deleteRoleAsync, deletingRole } = useDeleteService()

  useMount(() => {
    if (!appId || !roleId) return
    fetchAppRoleDetailAsync({ application_id: appId, role_id: roleId })
  })

  const onDeleteRole = useCallback(() => {
    if (!appId || !roleId) return
    deleteRoleAsync({ appId, roleId })
  }, [appId, roleId, deleteRoleAsync])

  if (fetchingAppRoleDetail || !role) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_ROLES },
          { name: 'Role Details' }
        ]}
      />

      <article className="flex flex-col gap-2">
        <RoleInfo role={role} />

        <RolePermissions permissions={role.permissions} />

        <DangerZone type="role">
          <DeleteDoubleConfirmPopUp
            item={{ name: role.name, type: 'role' }}
            onExecute={onDeleteRole}
            loading={deletingRole}
          >
            <Button styleVariants={{ kind: 'danger', type: 'text' }}>
              Delete Role
              <Trash2 size={16} />
            </Button>
          </DeleteDoubleConfirmPopUp>
        </DangerZone>
      </article>
    </>
  )
}

export default AppRoleDetail
