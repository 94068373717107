import { Logo } from '$assets/svg'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { onOneOf } from '$router/config'
import Link from '$router/Link'
import { Menu } from 'lucide-react'
import { useCallback, useState } from 'react'
import Drawer from 'react-modern-drawer'
import {
  drawerBody,
  drawerContainer,
  drawerHeader,
  drawerInnerContainer,
  menuToggleButtonStyle,
  NavLinkStyle
} from './styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { MainSidebarMenu } from './constants'

const { colors } = themeVars

const SidebarToggleMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const { user } = useAuthContext()

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  return (
    <>
      <button className={menuToggleButtonStyle} onClick={handleOpenDrawer}>
        <Menu size={20} color={colors.absolute.light} />
      </button>

      <Drawer
        open={drawerOpen}
        onClose={handleCloseDrawer}
        direction="left"
        className={drawerContainer}
        size={230}
        lockBackgroundScroll
      >
        <article className={drawerInnerContainer}>
          <header className={drawerHeader}>
            <Logo color="neutral.default" />
          </header>

          <main className={drawerBody}>
            {MainSidebarMenu.slice(
              0,
              user?.has_global_access ? undefined : 1
            ).map((menu, key) => {
              const Icon = menu.Icon
              const isActive = onOneOf([menu.to, ...(menu.subPath ?? [])])
              return (
                <Link
                  key={key}
                  to={menu.to}
                  onClick={handleCloseDrawer}
                  className={NavLinkStyle({ isActive })}
                >
                  <Icon size={18} />
                  <p>{menu.name}</p>
                </Link>
              )
            })}
          </main>
        </article>
      </Drawer>
    </>
  )
}

export default SidebarToggleMenu
