import * as Dialog from '@radix-ui/react-dialog'
import { AlertTriangle, Download } from 'lucide-react'
import CloseButton from '$components/CloseButton'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useRouterContext } from '$contexts/RouteContext/hooks'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars

interface IProps {
  isDirty: boolean
  isValid?: boolean
  loading?: boolean
  onConfirm?: () => Promise<void>
  type: 'create' | 'edit' | 'download'
}

const info: Record<
  IProps['type'],
  {
    title: string
    desc: string
  }
> = {
  create: {
    title: 'Are you sure, you want to go back to listing?',
    desc: 'This is an irreversible action. All data will be erased and cannot be retrieved.'
  },
  edit: {
    title: "Changes haven't been saved yet!",
    desc: 'All data will be erased and cannot be retrieved. Are you sure you want to leave?'
  },
  download: {
    title: 'Download .csv file to retrieve the information!',
    desc: 'In case of forgetting or lost information, please click to download button.'
  }
}

const RedirectPrompt = ({
  isDirty,
  loading,
  onConfirm,
  type,
  isValid
}: IProps) => {
  const [modalOpen, setModalOpen] = useState(false)

  const action = useRef<VoidFunction>()

  const { registerBeforeCallback } = useRouterContext()

  useEffect(
    () => {
      const cleanUp = registerBeforeCallback(redirect => {
        action.current = redirect
        if (isDirty) {
          setModalOpen(true)
          return false
        }
        return true
      })

      return cleanUp
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty]
  )

  const onClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const onDiscard = useCallback(() => {
    action.current?.()
    onClose()
  }, [onClose])

  const onSubmit = useCallback(async () => {
    await onConfirm?.()

    if (isValid === false) {
      onClose()
      return
    }

    onDiscard()
  }, [onConfirm, onDiscard, isValid, onClose])

  const { title, desc } = info[type]

  return (
    <Dialog.Root open={modalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent flex flex-col gap-4 max-w-[480px] p-8"
          aria-describedby=""
        >
          <Dialog.Title className="hidden" />

          <article className="flex items-center justify-between gap-2">
            <article className="inline-flex border border-[--colors-warning-20] text-[--colors-warning-default] bg-[--colors-alphaWarning-1] rounded-lg p-2.5">
              <AlertTriangle />
            </article>

            <CloseButton onClick={onClose} />
          </article>

          <p className="text-xl font-semibold">{title}</p>

          <p className="text-sm" style={{ color: colors.neutral[70] }}>
            {desc}
          </p>

          <article className="flex items-center justify-end gap-2">
            {type === 'edit' && (
              <>
                <Button
                  disabled={loading}
                  onClick={onDiscard}
                  styleVariants={{ kind: 'neutral', type: 'outlined' }}
                >
                  Discard
                </Button>

                <Button autoFocus disabled={loading} onClick={onSubmit}>
                  {loading && <Spinner />}
                  Save Changes
                </Button>
              </>
            )}

            {type === 'create' && (
              <>
                <Button
                  onClick={onClose}
                  styleVariants={{ kind: 'neutral', type: 'outlined' }}
                >
                  Cancel
                </Button>

                <Button autoFocus onClick={onDiscard}>
                  Confirm
                </Button>
              </>
            )}

            {type === 'download' && (
              <>
                <Button
                  onClick={onClose}
                  styleVariants={{ kind: 'neutral', type: 'outlined' }}
                >
                  Cancel
                </Button>

                <Button autoFocus onClick={onSubmit}>
                  Download .csv File
                  <Download />
                </Button>
              </>
            )}
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default RedirectPrompt
