import { cn } from '$app/utils'
import AppEnvironment from '$components/AppEnvironment'
import AppLogo from '$components/AppLogo'
import Loading from '$components/Loading'
import { useApiListingParams } from '$hooks/actions'
import { useAppService } from '$hooks/services'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useCallback, useEffect, useRef, useState } from 'react'

const { colors } = themeVars

interface IProps {
  selectedAppId?: number
  onChangeSelectedAppId: (appId: number) => void
}

const ChooseApp = ({ onChangeSelectedAppId }: IProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const { searchValue, updateSearchValue, debouncedSearchValue } =
    useApiListingParams()

  const cardRefs = useRef<(HTMLElement | null)[]>([])

  const { fetchAppSelectListAsync, fetchingAppSelectList, app_option_list } =
    useAppService()

  useEffect(() => {
    if (activeIndex === null) return
    cardRefs.current[activeIndex]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }, [activeIndex])

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!app_option_list.length) return

      if (event.key === 'ArrowUp') {
        setActiveIndex(prevIndex => {
          const idx = prevIndex ?? 0
          return !idx ? app_option_list.length - 1 : idx - 1
        })
        return
      }

      if (event.key === 'ArrowDown') {
        setActiveIndex(prevIndex => {
          const idx = prevIndex ?? 0
          return idx === app_option_list.length - 1 ? 0 : idx + 1
        })
        return
      }

      if (event.key === 'Enter') {
        if (activeIndex === null) return
        onChangeSelectedAppId(app_option_list[activeIndex].id)
        return
      }
    },
    [app_option_list, onChangeSelectedAppId, activeIndex]
  )

  useEffect(() => {
    fetchAppSelectListAsync({
      q: debouncedSearchValue,
      per_page: 10
    }).then(({ data }) => {
      if (!data.length) return
      setActiveIndex(0)
    })
  }, [fetchAppSelectListAsync, debouncedSearchValue])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const onClickCard = useCallback(
    (appId: number) => {
      onChangeSelectedAppId(appId)
    },
    [onChangeSelectedAppId]
  )

  return (
    <article
      className={cn(
        'flex flex-col rounded-lg overflow-hidden h-[600px] relative',
        defaultBorder
      )}
      style={{ background: colors.area.low }}
    >
      {fetchingAppSelectList && <Loading />}

      <article className={cn('flex p-2.5 g-2.5', defaultBackground)}>
        <BaseText
          elementControlProps={{
            className: 'border-transparent flex p-2.5 gap-2.5'
          }}
          affix={{
            pre: <Icon namespace="Search" width={16} />,
            post: searchValue ? (
              <button type="button" onClick={() => updateSearchValue('')}>
                <Icon namespace="Cross" width={18} />
              </button>
            ) : undefined
          }}
          inputProps={{
            placeholder: 'Search here...',
            autoComplete: 'off',
            value: searchValue,
            onChange: e => updateSearchValue(e.currentTarget.value)
          }}
        />
      </article>

      {!fetchingAppSelectList && (
        <>
          {!app_option_list.length && (
            <article className="flex items-center justify-center flex-1 text-[--colors-neutral-50]">
              App Not Found!
            </article>
          )}

          <article className="flex flex-col p-4 gap-1 overflow-y-auto">
            {app_option_list.map((v, k) => {
              const isActive = k === activeIndex

              return (
                <article
                  key={k}
                  className={cn(
                    'flex flex-col cursor-pointer gap-2 rounded-lg hover:bg-[--colors-neutral-10] duration-200',
                    isActive
                      ? 'border-2 border-[--colors-primary-80]'
                      : 'border-2 border-[--colors-neutral-10]'
                  )}
                  ref={el => (cardRefs.current[k] = el)}
                  onClick={() => {
                    onClickCard(v.id)
                  }}
                >
                  <article className="flex items-center gap-2 p-4 bg-[--colors-area-high] rounded-lg">
                    <AppLogo
                      isPublished={v.is_published}
                      imgUrl={v.logo?.url}
                      brand={v.brand}
                      size="big"
                    />

                    <article className="flex-1 flex flex-col">
                      <p className="text-sm font-medium truncate">{v.name}</p>

                      <AppEnvironment environment={v.environment} />
                    </article>

                    {/* <article className="flex gap-1 items-center text-[--colors-text-light]">
                                    <span className="text-sm font-medium">Switch</span>
                                    <Icon namespace="Forward" width={18} />
                                </article> */}
                  </article>
                </article>
              )
            })}
          </article>
        </>
      )}
    </article>
  )
}

export default ChooseApp
