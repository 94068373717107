import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

export const style = {
  warning: {
    background: colors.alphaWarning[0],
    borderColor: colors.warning[markedDefaultKey],
    iconColor: colors.warning[80]
  },
  success: {
    background: colors.success[0],
    borderColor: colors.success[markedDefaultKey],
    iconColor: colors.success[80]
  }
}

export type TQueryMessageType = keyof typeof style
