import { cn, formatNumber } from '$app/utils'
import {
  defaultBackground,
  defaultBorder,
  description,
  title,
  defaultTitleFontWeight
} from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { ItemType } from './types'
import { INFO } from './constants'
import { useAppDetailStore } from '$hooks/stores'
import { Button } from '@genie-fintech/ui/components'
import { useCallback, useMemo } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { redirect } from '$router/config'

const { colors } = themeVars

interface IProps {
  item: ItemType
  count: number
}

const AppSummaryCard = ({ item, count }: IProps) => {
  const { appId, appName } = useAppDetailStore(
    useShallow(state => {
      const { id: appId, name: appName } = state.appDetail ?? {}
      return { appId, appName }
    })
  )

  const { Icon, desc, redirectPath } = INFO[item]

  const label = useMemo(
    () => ['TOTAL', `${appName}'s`, `${item}s`.toLocaleUpperCase()].join(' '),
    [appName, item]
  )

  const onClick = useCallback(() => {
    redirect(redirectPath, { params: { appId } })
  }, [redirectPath, appId])

  return (
    <article
      className={cn(
        'flex-1 flex flex-col rounded-lg',
        defaultBorder,
        defaultBackground
      )}
    >
      <article className="flex flex-col p-6 gap-3">
        <article
          className="inline-flex justify-center items-center w-14 h-14 rounded-full"
          style={{ background: colors.area.low }}
        >
          <Icon size={24} />
        </article>

        <article className="flex flex-col gap-1">
          <p className={title['type3']}>{label}</p>
          <p className={defaultTitleFontWeight} style={{ fontSize: 32 }}>
            {formatNumber(count)}
          </p>
        </article>

        <p className={description}>{desc}</p>
      </article>

      <article
        className="flex justify-end p-5 gap-2.5"
        style={{ background: colors.alphaNeutral[0] }}
      >
        <Button
          styleVariants={{ kind: 'neutral', type: 'outlined' }}
          onClick={onClick}
        >
          View All
        </Button>
      </article>
    </article>
  )
}

export default AppSummaryCard
