import { Folders, ListTree, LucideIcon, Users2 } from 'lucide-react'
import { ItemType } from './types'
import { ROUTE_NAMES } from '$router/config'

type ItemData = {
  Icon: LucideIcon
  desc: string
  redirectPath: ROUTE_NAMES
}

export const INFO: Record<ItemType, ItemData> = {
  [ItemType.USER]: {
    Icon: Users2,
    desc: 'Track the total number of users on app for comprehensive system usage insights.',
    redirectPath: ROUTE_NAMES.APP_USERS
  },
  [ItemType.GROUP]: {
    Icon: Folders,
    desc: 'Review the total number of groups in app for streamlined user access control.',
    redirectPath: ROUTE_NAMES.APP_GROUPS
  },
  [ItemType.ROLE]: {
    Icon: ListTree,
    desc: 'Monitor the total number of roles in app for effective user management and permissions.',
    redirectPath: ROUTE_NAMES.APP_ROLES
  }
}
