import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppGroupService, useDeleteService } from '$hooks/services'
import { useMount } from 'ahooks'
import GroupInfo from './GroupInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import GroupRoles from './GroupRoles'
import GroupUsers from './GroupUsers'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { useCallback } from 'react'
import { Button } from '@genie-fintech/ui/components'
import { Trash2 } from 'lucide-react'

const AppGroupDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { groupId, appId } = params

  const { group, fetchAppGroupDetail, fetchingAppGroupDetail } =
    useAppGroupService()

  const { deleteGroupAsync, deletingGroup } = useDeleteService()

  useMount(() => {
    if (!appId || !groupId) return
    fetchAppGroupDetail({ application_id: appId, group_id: groupId })
  })

  const onDeleteGroup = useCallback(() => {
    if (!appId || !groupId) return
    deleteGroupAsync({ appId, groupId })
  }, [appId, groupId, deleteGroupAsync])

  if (fetchingAppGroupDetail || !group) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: `App Details`, path: ROUTE_NAMES.APP_GROUPS },
          { name: `Group Details` }
        ]}
      />

      <article className="flex flex-col gap-2">
        <GroupInfo group={group} />

        <GroupRoles groupName={group.name} />

        <GroupUsers groupName={group.name} />

        <DangerZone type="group">
          <DeleteDoubleConfirmPopUp
            item={{ name: group.name, type: 'group' }}
            loading={deletingGroup}
            onExecute={onDeleteGroup}
          >
            <Button styleVariants={{ kind: 'danger', type: 'text' }}>
              Delete Group
              <Trash2 size={16} />
            </Button>
          </DeleteDoubleConfirmPopUp>
        </DangerZone>
      </article>
    </>
  )
}

export default AppGroupDetail
