import { cn } from '$app/utils'
import RedirectPrompt from '$blocks/RedirectPrompt'
import UserQueryMessage from '$components/UserQueryMessage'
import { TQueryMessageType } from '$components/UserQueryMessage/constants'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { ROUTE_NAMES } from '$router/config'
import { getQueryUserByEmail } from '$services/api'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { zodResolver } from '@hookform/resolvers/zod'
import { useRequest } from 'ahooks'
import { CornerDownLeft, XCircle } from 'lucide-react'
import { useCallback, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'

const { colors } = themeVars

enum USER_STATUS {
  NEW_USER = 'new_user',
  CURRENT_APP_USER = 'current_app_user',
  OTHER_APP_USER = 'other_app_user'
}

const info: Record<
  string,
  { title: string; desc: string; type: TQueryMessageType }
> = {
  [USER_STATUS.CURRENT_APP_USER]: {
    title: 'You can not add this user!',
    desc: 'This user is already in the current application and it will be duplicate data in one application!',
    type: 'warning'
  },
  [USER_STATUS.OTHER_APP_USER]: {
    title: 'Do you want to add this user?',
    desc: 'This user is already in the Carro SSO system!',
    type: 'success'
  }
}

const schema = z.object({
  email: z.string().trim().min(1, { message: 'Required!' }).email()
})

type TFormValues = z.infer<typeof schema>

interface IProps {
  onSuccess?: (email: string, userId?: string) => void
  email: string
}

const QueryUser = ({ onSuccess, email }: IProps) => {
  const [userStatus, setUserStatus] = useState('')

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const proxyRedirect = useRedirectProxy()

  const {
    data: queryData,
    run: queryUser,
    loading: queryingUser
  } = useRequest(getQueryUserByEmail, {
    manual: true,
    onSuccess: ({ data: { status } }) => {
      const _status = (() => {
        if (status.includes('New')) return USER_STATUS.NEW_USER

        if (status.includes('Old')) return USER_STATUS.CURRENT_APP_USER

        if (status.includes('Exists')) return USER_STATUS.OTHER_APP_USER

        return status
      })()

      setUserStatus(_status)
    }
  })

  const { id: userId } = queryData?.data ?? {}

  const {
    handleSubmit,
    control,
    formState: { isDirty }
  } = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      email
    }
  })

  const emailValue = useWatch({ name: 'email', control })

  const onBack = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_USERS, { params })
  }, [proxyRedirect, params])

  const onAddUser = useCallback(() => {
    onSuccess?.(emailValue, userId)
  }, [onSuccess, emailValue, userId])

  const onSubmit = handleSubmit((values: TFormValues) => {
    if (!appId) return

    queryUser({ application_id: appId, ...values })
  })

  const {
    title = '',
    desc,
    type = ''
  } = (() => {
    if (!userStatus || userStatus === USER_STATUS.NEW_USER) return undefined

    return info[userStatus]
  })() ?? {}

  return (
    <>
      <form
        onSubmit={onSubmit}
        className={cn(
          'flex flex-col rounded-lg border px-12 py-7 gap-6',
          defaultBackground,
          defaultBorder
        )}
      >
        <article className="flex flex-col gap-1">
          <p className="font-semibold">Add new user or query with email</p>
          <p className="text-xs" style={{ color: colors.neutral[50] }}>
            By inputting the user's email to query or add user into this
            application.
          </p>
        </article>

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <BaseText
                elementControlProps={{ style: { padding: '20px 24px' } }}
                affix={{
                  pre: <Icon namespace="Search" width={16} />,
                  post: queryingUser ? (
                    <Spinner />
                  ) : (
                    <article className="flex flex-row-reverse gap-1">
                      <article
                        className="flex items-center gap-1"
                        style={{ color: colors.text.disabled }}
                      >
                        <CornerDownLeft size={14} />
                        <span className="text-xs font-medium">Enter</span>
                      </article>

                      {field.value && (
                        <button
                          type="button"
                          onClick={() => field.onChange('')}
                          style={{ color: colors.text.light }}
                        >
                          <XCircle size={20} />
                        </button>
                      )}
                    </article>
                  )
                }}
                inputProps={{
                  type: 'text',
                  value: field.value,
                  onChange: e => {
                    field.onChange(e.currentTarget.value)
                    setUserStatus('')
                  },
                  placeholder: 'Search with email here...'
                }}
                error={!!error?.message}
                message={error?.message}
              />
            )
          }}
        />

        {!!type && (
          <UserQueryMessage title={title} desc={desc} type={type}>
            {userStatus === USER_STATUS.OTHER_APP_USER && (
              <Button
                onClick={onAddUser}
                styleVariants={{ kind: 'success', size: 'small' }}
              >
                Add
              </Button>
            )}
          </UserQueryMessage>
        )}

        <footer className="flex items-center justify-between gap-1">
          <Button
            onClick={onBack}
            disabled={queryingUser}
            styleVariants={{ kind: 'neutral', type: 'outlined' }}
          >
            Back
          </Button>

          {userStatus === USER_STATUS.NEW_USER && (
            <Button onClick={onAddUser}>Add User</Button>
          )}
        </footer>
      </form>

      <RedirectPrompt isDirty={isDirty} type="create" />
    </>
  )
}

export default QueryUser
