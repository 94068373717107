import RedirectPrompt from '$blocks/RedirectPrompt'
import Breadcrumb from '$components/Breadcrumb/v2'
import ChooseApp from '$components/ChooseApp'
import FooterAction from '$components/FooterAction'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService } from '$hooks/services'
import UserRoles from '$pages/AppUser/Create/Form/UserRoles'
import { redirect, ROUTE_NAMES } from '$router/config'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'

const { colors } = themeVars

const schema = z.object({
  roles: z.number().array().min(1, 'Please assign one role at least!')
})

type TFormValues = z.infer<typeof schema>

const GlobalUserAddApplication = () => {
  const [appId, setAppId] = useState<number | undefined>(undefined)

  const [step, setStep] = useState(1)

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: { roles: [] }
  })

  const proxyRedirect = useRedirectProxy()

  const {
    route: { params }
  } = useRouteSummary()

  const { appUserId } = params

  const { attachRoleToUserAsync, attachingRoleToUser } = useAppUserService()

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'roles' as never,
    control
  })

  const selectedRoles = useWatch({ name: 'roles', control })

  const onChangeAppId = useCallback((id: number) => {
    setAppId(id)
    setStep(prev => prev + 1)
  }, [])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.GLOBAL_APP_USER_DETAIL, { params })
  }, [params])

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.GLOBAL_APP_USER_DETAIL, { params })
  }, [proxyRedirect, params])

  const onBack = useCallback(() => {
    if (step === 1) {
      onCancel()
      return
    }
    setAppId(undefined)
    setStep(prev => prev - 1)
  }, [step, onCancel])

  const onSubmit = handleSubmit(({ roles }) => {
    if (!appId || !appUserId) return

    attachRoleToUserAsync(appId, appUserId, roles).then(onSuccess)
  })

  const isProcessing = attachingRoleToUser

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.GLOBAL_APP_USERS}
        data={[{ name: 'User Details' }]}
      />

      <form
        className="flex flex-col max-w-[1056px] mx-auto w-full"
        onSubmit={onSubmit}
      >
        {step === 1 && (
          <article className="flex flex-col w-full max-w-[736px] mx-auto px-12 py-7 gap-6">
            <article className="flex flex-col gap-1">
              <p className="font-semibold">CHOOSE APPLICATIONS TO USE</p>
              <p className="text-xs" style={{ color: colors.neutral[50] }}>
                To access on these applications which is using by Carro SSO
                Login.
              </p>
            </article>

            <ChooseApp
              selectedAppId={appId}
              onChangeSelectedAppId={onChangeAppId}
            />

            <article>
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                onClick={onBack}
              >
                Back
              </Button>
            </article>
          </article>
        )}

        {step === 2 && (
          <UserRoles
            roles={selectedRoles}
            onChange={replace}
            errorMessage={errors.roles?.message}
            appId={appId}
          />
        )}

        {step === 2 && (
          <FooterAction>
            <article className="flex w-full max-w-[1056px] gap-2 mx-auto">
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                disabled={isProcessing}
                onClick={onBack}
              >
                Back
              </Button>

              <article className="flex-1 flex justify-end gap-2">
                <Button
                  disabled={isProcessing}
                  styleVariants={{ type: 'text' }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <Button type="submit" disabled={isProcessing}>
                  {isProcessing && <Spinner />}
                  Create
                </Button>
              </article>
            </article>
          </FooterAction>
        )}
      </form>

      <RedirectPrompt
        isDirty={isDirty}
        isValid={isValid}
        type="edit"
        onConfirm={onSubmit}
        loading={isProcessing}
      />
    </>
  )
}

export default GlobalUserAddApplication
