import { useContext, useEffect, useRef, useState } from 'react'
import { RouteContext } from '../context'
import {
  redirect,
  routeState,
  summaryState,
  watchRouteState,
  watchSummaryState
} from '$router/config'
import { TCallBackFn } from '../types'

export const useRouterContext = () => {
  const context = useContext(RouteContext)

  if (!context) {
    throw new Error('useRouterContext must be used within an RouterProvider')
  }

  return context
}

export const useRouteSummary = () => {
  const { summaryState } = useRouterContext()

  return summaryState
}

export const useRoute = () => {
  const { routeState } = useRouterContext()

  return routeState
}

export const useRedirectProxy = () => {
  const { beforeCallbacks } = useRouterContext()

  const proxyRedirect: typeof redirect = (...args) => {
    const action = () => redirect(...args)

    const Invalid = beforeCallbacks.current
      .map(callbackFn => callbackFn(action))
      .includes(false)

    if (Invalid) return

    action()
  }

  return proxyRedirect
}

export const useRouterValues = () => {
  const beforeCallbacks = useRef<Array<TCallBackFn>>([])

  const registerBeforeCallback = (callbackFn: TCallBackFn) => {
    beforeCallbacks.current.push(callbackFn)

    return () => {
      beforeCallbacks.current = beforeCallbacks.current.filter(
        d => d !== callbackFn
      )
    }
  }

  const [routeStateValue, setRouteStateState] = useState(routeState)
  const [summaryStateValue, setSummaryStateState] = useState(summaryState)

  useEffect(() => watchRouteState(setRouteStateState), [])
  useEffect(() => watchSummaryState(setSummaryStateState), [])

  return {
    routeState: routeStateValue,
    summaryState: summaryStateValue,
    beforeCallbacks,
    registerBeforeCallback
  }
}
