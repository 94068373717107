import Breadcrumb from '$components/Breadcrumb/v2'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppPermissionService, useDeleteService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import PermissionInfo from './PermissionInfo'
import Loading from '$components/Loading'
import DangerZone from '$components/DangerZone'
import DeleteDoubleConfirmPopUp from '$components/DeleteDoubleConfirmPopUp'
import { Trash2 } from 'lucide-react'
import { Button } from '@genie-fintech/ui/components'
import { useCallback } from 'react'

const AppPermissionDetail = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, permissionId } = params

  const { permission, fetchAppPermissionDetail, fetchingAppPermissionDetail } =
    useAppPermissionService()

  const { deletePermissionAsync, deletingPermission } = useDeleteService()

  useMount(() => {
    if (!appId || !permissionId) return
    fetchAppPermissionDetail({
      application_id: appId,
      permission_id: permissionId
    })
  })

  const onDeletePermission = useCallback(() => {
    if (!appId || !permissionId) return
    deletePermissionAsync({ appId, permissionId })
  }, [appId, permissionId, deletePermissionAsync])

  if (fetchingAppPermissionDetail || !permission) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_ROLES },
          { name: 'Permission Details' }
        ]}
      />

      <article className="flex flex-col gap-2">
        <PermissionInfo permission={permission} />

        <DangerZone type="permission">
          <DeleteDoubleConfirmPopUp
            item={{ name: permission.name, type: 'permission' }}
            onExecute={onDeletePermission}
            loading={deletingPermission}
          >
            <Button styleVariants={{ kind: 'danger', type: 'text' }}>
              Delete Permission
              <Trash2 size={16} />
            </Button>
          </DeleteDoubleConfirmPopUp>
        </DangerZone>
      </article>
    </>
  )
}

export default AppPermissionDetail
