import { FC, PropsWithChildren } from 'react'
import { BGBlurCover, NoiseOverlay } from './styles.css'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

const LayoutWithBackground: FC<PropsWithChildren<TagsNoRef<'span'>>> = ({
  children,
  ...props
}) => {
  return (
    <span {...propsWithClassNames(props, BGBlurCover)}>
      <span className={NoiseOverlay} />

      {children}
    </span>
  )
}

export default LayoutWithBackground
