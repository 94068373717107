import { APP_TOKEN_KEY } from '$app/constants'
import { redirect, ROUTE_NAMES } from '$app/router/config'
import { getProfile, updateAxiosHeaderToken } from '$app/services/api'
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage
} from '$app/utils'
import { useRequest } from 'ahooks'
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { AuthContext } from './context'
import Loading from '$components/Loading'
import { useAxiosInterceptors } from '$hooks/actions'
import { useAppScopeService, useCountryService } from '$hooks/services'

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState(getLocalStorage<string>(APP_TOKEN_KEY))

  const {
    data: profileData,
    run: fetchProfile,
    loading: fetchingProfile
  } = useRequest(getProfile, {
    manual: true
  })

  const { fetchCountries } = useCountryService()

  const { fetchAppScope } = useAppScopeService()

  const { data: profile } = profileData ?? {}

  const updateToken = useCallback((value?: string) => {
    setToken(value)

    if (value) {
      setLocalStorage(APP_TOKEN_KEY, value)
      return
    }

    removeLocalStorage(APP_TOKEN_KEY)
  }, [])

  useAxiosInterceptors(() => updateToken(undefined))

  useEffect(() => {
    updateAxiosHeaderToken(token)

    if (!token) {
      redirect(ROUTE_NAMES.LOGIN, { replace: true })
      return
    }

    fetchProfile()

    fetchCountries()

    fetchAppScope()
  }, [token, fetchProfile, fetchCountries, fetchAppScope])

  if (fetchingProfile) return <Loading />

  return (
    <AuthContext.Provider value={{ user: profile, token, updateToken }}>
      {children}
    </AuthContext.Provider>
  )
}
