import { useTheme } from '@genie-fintech/ui/hooks'
import { Icon } from '@genie-fintech/ui/icons'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { dark } from '@genie-fintech/ui/style/theme/colors/sets'
import {
  backgrounColor,
  NavbarActionIconStyle
} from '$layouts/PageLayout/styles.css'

const ThemeToggleButton = () => {
  const {
    mode: { isDarkMode },
    toggleTheme
  } = useTheme()

  return (
    <button
      type="button"
      className={NavbarActionIconStyle}
      style={assignInlineVars({ [backgrounColor]: dark.colors.area.disabled })}
      onClick={toggleTheme}
    >
      <Icon
        namespace={isDarkMode ? 'Dark' : 'Light'}
        color="absolute.light"
        width={16}
      />
    </button>
  )
}

export default ThemeToggleButton
