import { TPasswordPolicy } from '$services/api'

export const PASSWORD_POLICY_CONFIG = [
  {
    id: 1,
    label: 'No more than 2 identical characters in a row'
  },
  {
    id: 2,
    label: 'Special characters (!@#$%^&*)'
  },
  {
    id: 3,
    label: 'Lower case (a-z), upper case (A-Z) and numbers (0-9)'
  },
  {
    id: 4,
    label: 'Must have ### characters in length'
  },
  {
    id: 5,
    label: 'Non-empty password required'
  }
]

export const MIN_LENGTH_ID = 4

export const TWO_IDENTICAL_ROW_REGEX = /^(?!.*(.)\1{2,}).*$/

export const SPECIAL_LETTER_REGEX = /^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/

export const LOWERCASE_UPPERCASE_NUMBER_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/

export const checkPolicy = (policy: TPasswordPolicy, password: string) => {
  switch (policy.id) {
    case 1:
      return !!password.length && TWO_IDENTICAL_ROW_REGEX.test(password)
    case 2:
      return SPECIAL_LETTER_REGEX.test(password)
    case 3:
      return LOWERCASE_UPPERCASE_NUMBER_REGEX.test(password)
    case 4:
      return !!policy.min_length && password.length >= +policy.min_length
    case 5:
      return !!password.length
    default:
      return false
  }
}

export const checkPolicies = (polices: TPasswordPolicy[], password: string) => {
  return (
    !polices.length || polices.map(v => checkPolicy(v, password)).every(v => v)
  )
}
