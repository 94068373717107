import { cn } from '$app/utils'
import { RoleIcon } from '$assets/svg'
import Breadcrumb from '$components/Breadcrumb/v2'
import Loading from '$components/Loading'
import Pagination from '$components/Pagination'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppRoleService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TAppRoleListResponse, TID } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  defaultBorder
} from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Table2 } from 'lucide-react'
import { useCallback, useEffect } from 'react'

const columns: {
  key: keyof Omit<TAppRoleListResponse['data'][number], 'id'>
  value: string
}[] = [
  { key: 'name', value: 'ROLE NAME' },
  { key: 'group', value: 'GROUP' },
  { key: 'user_count', value: 'USERS' },
  { key: 'permission_count', value: 'PERMISSIONS' }
]

const { colors } = themeVars

const AppRoleList = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const {
    fetchAppRoleList,
    fetchingAppRoleList,
    roles: { list, meta }
  } = useAppRoleService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppRoleList({
      page,
      per_page: perPage,
      application_id: appId,
      q: debouncedSearchValue
    })
  }, [appId, fetchAppRoleList, page, perPage, debouncedSearchValue])

  const handleOnClickAddNew = useCallback(() => {
    redirect(ROUTE_NAMES.APP_ROLE_CREATE, { params })
  }, [params])

  const onClickRow = useCallback(
    (roleId: TID) => {
      redirect(ROUTE_NAMES.APP_ROLE_DETAIL, { params: { ...params, roleId } })
    },
    [params]
  )

  const handleOnClickImport = useCallback(() => {
    redirect(ROUTE_NAMES.APP_ROLES_CSV_IMPORT, { params })
  }, [params])

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <article
        className={cn(
          'flex-1 flex flex-col rounded-lg',
          defaultBackground,
          defaultBorder
        )}
      >
        <header
          className="flex items-center gap-2 px-4 py-3 border-b"
          style={{ borderColor: colors.neutral[10], color: colors.text.light }}
        >
          <RoleIcon />

          <p className="flex-1 text-xl font-semibold">Roles</p>

          <Button
            styleVariants={{ kind: 'neutral', type: 'outlined' }}
            onClick={handleOnClickImport}
          >
            <Table2 size={14} />
            Import CSV File
          </Button>

          <Button onClick={handleOnClickAddNew}>
            <Icon namespace="Add" />
            Add New
          </Button>
        </header>

        <main className="flex flex-col gap-y-4 flex-1 px-12 py-7">
          <article className="flex flex-col gap-y-1">
            <p className="font-semibold">{appName}'s ROLES</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Define and assign roles to control access and permissions within
              the system.
            </p>
          </article>

          <article className="flex gap-x-2 items-center justify-between">
            <article>
              <BaseText
                affix={{
                  pre: <Icon namespace="Search" width={16} />,
                  post: searchValue ? (
                    <button onClick={() => updateSearchValue('')}>
                      <Icon namespace="Cross" width={18} />
                    </button>
                  ) : undefined
                }}
                inputProps={{
                  type: 'text',
                  value: searchValue,
                  onChange: e => updateSearchValue(e.currentTarget.value),
                  placeholder: 'Search here...'
                }}
              />
            </article>

            {!!meta?.total && (
              <p className="text-[--colors-text-disabled] text-sm font-medium">
                {meta.total} ROLE{meta.total > 1 && 'S'}
              </p>
            )}
          </article>

          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      NO
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>

                {!fetchingAppRoleList && (
                  <tbody className={tableBody}>
                    {list.map((data, rowKey) => (
                      <tr
                        key={rowKey}
                        className={cn(tableRow, 'cursor-pointer')}
                        onClick={() => onClickRow(data.id)}
                      >
                        <td className={customTableCell}>
                          {(page - 1) * perPage + rowKey + 1}
                        </td>
                        {columns.map((col, colKey) => (
                          <td key={colKey} className={customTableCell}>
                            {(() => {
                              if (col.key === 'group') {
                                return data.group.name
                              }

                              return data[col.key] ?? 'N/A'
                            })()}
                          </td>
                        ))}
                      </tr>
                    ))}

                    {!list.length && !fetchingAppRoleList && (
                      <tr className={tableRow}>
                        <td
                          colSpan={columns.length + 1}
                          style={{ textAlign: 'center' }}
                          className={customTableCell}
                        >
                          NO DATA
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </main>
          </article>

          {fetchingAppRoleList && <Loading />}
        </main>

        {!!list.length && (
          <footer className="sticky flex justify-between items-center bottom-0 bg-[--colors-area-high] px-12 py-4">
            <Pagination meta={meta} pagerProps={pagerProps} />
          </footer>
        )}
      </article>
    </>
  )
}

export default AppRoleList
