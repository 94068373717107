import { cn } from '$app/utils'
import LoginDevices from '$components/LoginDevices'
import { TAppHome } from '$services/api'
import { card, description, title } from '$styles/common.css'

const Last30MinLoginDevices = ({
  recent_active_users
}: {
  recent_active_users: TAppHome['recent_active_users']
}) => {
  const devices = recent_active_users.flatMap(v => v)

  return (
    <article className={cn('flex flex-col gap-6 px-5 py-4', card)}>
      <article className="flex flex-col gap-1">
        <p className={title['type3']}>DEVICES LOG IN 30MINS</p>
        <p className={description}>These are the devices that user login.</p>
      </article>

      <article className="flex flex-col gap-2">
        <LoginDevices data={devices} />
      </article>
    </article>
  )
}

export default Last30MinLoginDevices
