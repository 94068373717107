import { themeVars } from '@genie-fintech/ui/style/theme'
import { Info } from 'lucide-react'
import { FC, PropsWithChildren } from 'react'
import { style } from './constants'

const { colors } = themeVars

const UserQueryMessage: FC<
  PropsWithChildren<{ title: string; desc?: string; type: keyof typeof style }>
> = ({ title, desc, type, children }) => {
  const { background, borderColor, iconColor } = style[type]

  return (
    <article
      className="flex px-4 py-2.5 rounded border"
      style={{ background, borderColor }}
    >
      <article className="flex flex-1 items-center gap-2">
        <Info size={20} style={{ color: iconColor }} />

        <article className="flex flex-1 flex-col gap-0.5 justify-center">
          <p
            className="text-sm font-medium"
            style={{ color: colors.neutral[80] }}
          >
            {title}
          </p>
          <p
            className="text-xs font-medium"
            style={{ color: colors.neutral[60] }}
          >
            {desc}
          </p>
        </article>

        {children}
      </article>
    </article>
  )
}

export default UserQueryMessage
