import { themeVars } from '@genie-fintech/ui/style/theme'
import { TInvalidRecord } from '../../types'
import { AlertTriangle, Check } from 'lucide-react'
import { IconContainerStyle } from './styles.css'

const { colors } = themeVars

interface IProps {
  records: TInvalidRecord[]
}

const ImportInfo = ({ records }: IProps) => {
  const isSuccess = !records.length

  const Icon = isSuccess ? Check : AlertTriangle

  const title = isSuccess
    ? `All data has been imported.`
    : `${records.length} ${records.length > 1 ? 'rows' : 'row'} data couldn't be imported.`

  const desc = isSuccess
    ? `We will process with the imported data to contribute in the user table.`
    : `We couldn't process these rows due to missing or invalid data. Please check for missing  or incorrect data and import again.`

  return (
    <article className="flex gap-4 items-center">
      <article className={IconContainerStyle({ isSuccess })}>
        <Icon size={24} />
      </article>

      <article className="flex flex-col gap-1">
        <p className="text-sm font-medium">{title}</p>
        <p className="text-xs" style={{ color: colors.neutral[50] }}>
          {desc}
        </p>
      </article>
    </article>
  )
}

export default ImportInfo
