import CloseButton from '$components/CloseButton'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppLaunchpadService } from '$hooks/services'
import { TAppLaunchpad } from '$services/api'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import Drawer from 'react-modern-drawer'
import { z } from 'zod'
import Loading from '$components/Loading'
import DeleteWithConfirm from '$components/DeleteWithConfirm'
import { LaunchPadIcon } from '$assets/svg'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  defaultBorder
} from '$styles/common.css'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'
import Breadcrumb from '$components/Breadcrumb/v2'
import DescriptionTableColumn from '$components/DescriptionTableColumn'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { cn } from '$app/utils'

const { colors, zIndices } = themeVars

const columns: {
  key: keyof TAppLaunchpad | 'actions'
  value: string
}[] = [
  { key: 'name', value: 'NAME' },
  { key: 'url', value: 'URL' },
  { key: 'description', value: 'DESCRIPTION' },
  { key: 'actions', value: '' }
]

const schema = z.object({
  name: z.string().trim().min(1, 'Required!'),
  url: z.string().trim().min(1, 'Required!').url('Invalid URL!'),
  description: z.string().trim()
})

type TFormValues = z.infer<typeof schema>

const INITIAL_DEFAULT_VALUES: TFormValues = {
  name: '',
  url: '',
  description: ''
}

const AppLaunchPadSetting = () => {
  const [selectedID, setSelectedID] = useState<number>()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const { route } = useRouteSummary()

  const { appId } = route.params

  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: INITIAL_DEFAULT_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const {
    launchpads,
    fetchAppLaunchpads,
    fetchingAppLaunchpads,
    updateAppLuanchpadsAsync,
    updatingAppLuanchpads
  } = useAppLaunchpadService()

  useMount(() => {
    if (!appId) return
    fetchAppLaunchpads({ application_id: appId })
  })

  const handleOpenDrawer = useCallback((id?: number) => {
    setDrawerOpen(true)
    setSelectedID(id)
  }, [])

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false)
    setSelectedID(undefined)
    reset(INITIAL_DEFAULT_VALUES)
  }, [reset])

  const handleOnSubmit = useCallback(
    (formValues: TFormValues) => {
      if (!appId) return

      const payload = (() => {
        if (selectedID !== undefined) {
          return launchpads.map((v, k) => {
            if (selectedID === k) return formValues
            return v
          })
        }

        return [...launchpads, formValues]
      })()

      updateAppLuanchpadsAsync(appId, payload).then(handleCloseDrawer)
    },
    [appId, selectedID, launchpads, updateAppLuanchpadsAsync, handleCloseDrawer]
  )

  const handleOnEdit = useCallback(
    (key: number) => {
      handleOpenDrawer(key)
      reset(launchpads[key])
    },
    [launchpads, reset, handleOpenDrawer]
  )

  const handleOnDelete = useCallback(
    (key: number) => {
      if (!appId) return

      setSelectedID(key)

      const payload = launchpads.filter((_, index) => index !== key)
      updateAppLuanchpadsAsync(appId, payload).then(() => {
        setSelectedID(undefined)
      })
    },
    [appId, launchpads, updateAppLuanchpadsAsync]
  )

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <article
        className={cn(
          'flex-1 flex flex-col relative rounded-lg',
          defaultBackground,
          defaultBorder
        )}
      >
        <header className="flex items-center justify-between px-4 py-3 border-b border-[--colors-neutral-10]">
          <article className="flex items-center gap-x-2 text-[--colors-text-light]">
            <LaunchPadIcon />
            <p className="text-xl font-semibold">Launchpad</p>
          </article>
          <Button
            onClick={() => {
              handleOpenDrawer(undefined)
            }}
          >
            <Icon namespace="Add" />
            Add Launchpad
          </Button>
        </header>

        <main className="flex flex-col gap-2 px-10 py-5">
          <article className="flex flex-col gap-y-1">
            <p className="font-semibold">{appName}'s LAUNCHPADS</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Access key tools and resources quickly through customizable
              launchpads.
            </p>
          </article>

          <article className="flex gap-x-2 items-center justify-end">
            {!!launchpads.length && (
              <p className="text-[--colors-text-disabled] text-sm font-medium">
                {launchpads.length} LAUNCHPAD{launchpads.length > 1 && 's'}
              </p>
            )}
          </article>

          {fetchingAppLaunchpads && <Loading />}

          {!fetchingAppLaunchpads && (
            <article className={customTableContainer}>
              <main className={tableContainerInner}>
                <table className={table({ separator: 'line' })}>
                  <thead className={customTableHead}>
                    <tr className={tableRow}>
                      <th className={customTableCell} style={{ width: 0 }}>
                        NO
                      </th>
                      {columns.map((col, key) => (
                        <th key={key} className={customTableCell}>
                          {col.value}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody className={tableBody}>
                    {launchpads.map((data, rowKey, arr) => (
                      <tr key={rowKey} className={tableRow}>
                        <td className={customTableCell}>{rowKey + 1}</td>
                        {columns.map((col, colKey) => (
                          <td key={colKey} className={customTableCell}>
                            {(() => {
                              if (col.key === 'description') {
                                return (
                                  <DescriptionTableColumn
                                    desc={data.description}
                                  />
                                )
                              }

                              if (col.key === 'actions') {
                                return (
                                  <article className="flex gap-x-2 items-center">
                                    <Button
                                      styleVariants={{
                                        kind: 'neutral',
                                        category: 'icon',
                                        type: 'text'
                                      }}
                                      onClick={() => {
                                        handleOnEdit(rowKey)
                                      }}
                                    >
                                      <Icon namespace="EditPencil" />
                                    </Button>

                                    {arr.length > 1 && (
                                      <DeleteWithConfirm
                                        loading={
                                          selectedID === rowKey &&
                                          updatingAppLuanchpads
                                        }
                                        onConfirm={() => {
                                          handleOnDelete(rowKey)
                                        }}
                                      />
                                    )}
                                  </article>
                                )
                              }

                              return data[col.key]
                            })()}
                          </td>
                        ))}
                      </tr>
                    ))}

                    {!launchpads.length && (
                      <tr className={tableRow}>
                        <td
                          colSpan={columns.length + 2}
                          style={{ textAlign: 'center' }}
                          className={customTableCell}
                        >
                          NO DATA
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </main>
            </article>
          )}
        </main>
      </article>

      <Drawer
        open={drawerOpen}
        onClose={handleCloseDrawer}
        direction="right"
        className={cn('flex flex-col', defaultBorder)}
        style={{ zIndex: zIndices.sky, background: colors.area.high }}
        size={600}
        lockBackgroundScroll
      >
        <form
          className="flex flex-col flex-1"
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <header className="flex items-center gap-x-2 px-3 border-b border-[--colors-neutral-10] h-[56px]">
            <p className="flex-1 font-medium">
              {`${selectedID !== undefined ? 'Edit' : 'Add'} Launchpad`}
            </p>

            <CloseButton onClick={handleCloseDrawer} />
          </header>

          <main className="flex flex-col flex-1 p-3 gap-y-4">
            <article className="flex flex-col gap-y-1">
              <p className="text-xl font-semibold">Launchpad</p>
              <p className="text-xs text-[--colors-neutral-50]">
                Access key tools and resources quickly through customizable
                launchpads.
              </p>
            </article>

            <BaseHookFieldText
              name="name"
              control={control}
              label="Name"
              required
            />

            <BaseHookFieldText
              name="url"
              control={control}
              label="URL"
              required
            />

            <Textarea
              name="description"
              control={control}
              label="Description"
            />
          </main>

          <footer className="sticky bottom-0 flex justify-end items-center gap-x-2 p-3 shadow-[0px_-8px_32px_0px] shadow-[--colors-alphaNeutral-1]">
            <Button
              type="submit"
              disabled={!isDirty || updatingAppLuanchpads}
              className="w-full"
            >
              {updatingAppLuanchpads && <Spinner />}
              {selectedID === undefined && <Icon namespace="Add" />}
              {selectedID === undefined ? 'Add New' : 'Save Changes'}
            </Button>
          </footer>
        </form>
      </Drawer>
    </>
  )
}

export default AppLaunchPadSetting
