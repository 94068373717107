import { Button, Spinner } from '@genie-fintech/ui/components'
import * as Dialog from '@radix-ui/react-dialog'
import Switch from '$components/Switch'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { APP_STATUS } from './types'
import { INFO } from './constants'
import { useAppPublishUnpublishAction } from './hooks/useAppPublishUnpublishAction'
import { getStatusToUpdate } from './utils'
import WarningIcon from '$components/WarningIcon'

const { colors } = themeVars

interface Iprops {
  current_status: APP_STATUS
}

const PublishUnpublishButton = ({ current_status }: Iprops) => {
  const status_to_update = getStatusToUpdate(current_status)

  const { isProcessing, showConfirmPopup, onTrigger, onCancel, onConfirm } =
    useAppPublishUnpublishAction(status_to_update)

  const { title, desc } = INFO[status_to_update]

  return (
    <Dialog.Root open={showConfirmPopup}>
      <article className="w-[50px] flex items-center justify-center">
        <Switch
          checked={current_status === APP_STATUS.PUBLISH}
          onChange={onTrigger}
        />
      </article>

      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent flex flex-col max-w-[480px]"
          aria-describedby=""
        >
          <Dialog.Title></Dialog.Title>

          <article className="flex flex-col gap-6 p-8">
            <WarningIcon />

            <article className="flex flex-col gap-4">
              <p className="text-xl font-semibold">{title}</p>

              <p className="text-sm" style={{ color: colors.neutral[70] }}>
                {desc}
              </p>
            </article>
          </article>

          <article className="flex justify-end gap-2 p-4">
            <Button
              onClick={onCancel}
              styleVariants={{ kind: 'neutral', type: 'outlined' }}
              disabled={isProcessing}
            >
              Cancel
            </Button>

            <Button
              onClick={onConfirm}
              disabled={isProcessing}
              className="capitalize"
            >
              {isProcessing && <Spinner />}
              {status_to_update}
            </Button>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PublishUnpublishButton
