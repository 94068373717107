import { cn } from '$app/utils'
import { TInvalidRecord } from '$blocks/CSVImport/types'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { AlertTriangle } from 'lucide-react'

const { colors } = themeVars

const columns: {
  key: keyof TInvalidRecord
  value: string
}[] = [
  { key: 'row', value: 'ROW NUMBER' },
  { key: 'messages', value: 'ERROR' }
]

const InvalidRecords = ({ records }: { records: TInvalidRecord[] }) => {
  const renderColumn = (
    record: TInvalidRecord,
    colKey: keyof TInvalidRecord
  ) => {
    if (colKey === 'messages') {
      return (
        <article className="inline-flex flex-col">
          {record.messages.map((message, messageKey) => (
            <article key={messageKey} className="flex items-center gap-1">
              <AlertTriangle
                style={{ color: colors.warning[markedDefaultKey] }}
                size={16}
              />
              <p
                className="font-medium text-sm"
                style={{ color: colors.text.light }}
              >
                {message}
              </p>
            </article>
          ))}
        </article>
      )
    }
    return record[colKey]
  }

  return (
    <article className={customTableContainer}>
      <main className={tableContainerInner} style={{ maxHeight: 500 }}>
        <table className={table({ separator: 'line' })}>
          <thead className={customTableHead}>
            <tr className={tableRow}>
              {columns.map((col, key) => (
                <th key={key} className={cn(customTableCell, 'text-center')}>
                  {col.value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={tableBody}>
            {records.map((record, rowKey) => (
              <tr key={rowKey} className={tableRow}>
                {columns.map((col, colKey) => (
                  <td
                    key={colKey}
                    className={cn(customTableCell, 'text-center')}
                  >
                    {renderColumn(record, col.key)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    </article>
  )
}

export default InvalidRecords
