import { cn } from '$app/utils'
import { defaultBackground, defaultBorder, shadow } from '$styles/common.css'
import { FC, PropsWithChildren } from 'react'

const FooterAction: FC<PropsWithChildren> = ({ children }) => {
  return (
    <footer
      className={cn(
        'fixed bottom-0 inset-x-0 p-2',
        defaultBorder,
        shadow.small,
        defaultBackground
      )}
    >
      {children}
    </footer>
  )
}

export default FooterAction
