import Breadcrumb from '$components/Breadcrumb/v2'
import Loading from '$components/Loading'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppGroupService, useAppRoleService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '$app/utils'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button, Spinner, Tabs } from '@genie-fintech/ui/components'
import { useCountryStore } from '$hooks/stores'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Select from '$components/Select'
import { useCallback, useEffect } from 'react'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import FooterAction from '$components/FooterAction'
import RedirectPrompt from '$blocks/RedirectPrompt'
import { TAppRolePayload } from '$services/api'
import RolePermissions from '../Detail/RolePermissions'
import Asterisk from '$components/Asterisk'

const { colors } = themeVars

const AppRoleEdit = () => {
  const {
    route: { params }
  } = useRouteSummary()

  const { roleId, appId } = params

  const proxyRedirect = useRedirectProxy()

  const countryOptions = useCountryStore(state => state.countryOptions)

  const {
    role,
    fetchingAppRoleDetail,
    fetchAppRoleDetailAsync,
    updateRoleAsync,
    updatingRole
  } = useAppRoleService()

  const { groupOptions, fetchGroupSelectList } = useAppGroupService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'permissions' as never,
    control
  })

  const selectedPermissions = useWatch({ name: 'permissions', control })

  useMount(() => {
    if (!appId || !roleId) return
    fetchAppRoleDetailAsync({ application_id: appId, role_id: roleId }).then(
      ({ data }) => {
        const { group, ...rest } = data

        const modifiedData: TFormValues = {
          country_id: `${group.country_id}`,
          group: { label: group.name, value: `${group.id}` },
          ...rest
        }

        reset(modifiedData)
      }
    )
  })

  const countryId = useWatch({ name: 'country_id', control })

  useEffect(() => {
    if (!appId) return
    fetchGroupSelectList({
      application_id: appId,
      country_id: countryId ?? undefined
    })
  }, [appId, countryId, fetchGroupSelectList])

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_ROLE_DETAIL, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(() => {
    redirect(ROUTE_NAMES.APP_ROLE_DETAIL, { params })
  }, [params])

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId || !roleId) return

    const { group, name, description, permissions } = formValues

    const payload: TAppRolePayload = {
      group_id: +group.value,
      name,
      description,
      permissions
    }

    return updateRoleAsync(appId, roleId, payload).then(onSuccess)
  })

  if (fetchingAppRoleDetail || !role) return <Loading />

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_ROLES },
          { name: 'Role Details' }
        ]}
      />

      <form
        className="flex flex-col max-w-[1056px] mx-auto gap-2"
        onSubmit={onSubmit}
      >
        <article
          className={cn(
            'flex flex-col rounded-lg',
            defaultBackground,
            defaultBorder
          )}
        >
          <header
            className="flex items-center px-12 py-3 border-b"
            style={{ borderColor: colors.neutral[10] }}
          >
            <p
              className="text-xl font-semibold"
              style={{ color: colors.text.light }}
            >
              Edit {role.name}
            </p>
          </header>

          <article className="flex px-12 py-7">
            <article className="flex flex-col gap-6">
              <article className="flex flex-col gap-1">
                <p className="font-semibold">COUNTRY/REGION</p>
                <p className="text-xs" style={{ color: colors.neutral[50] }}>
                  Specify country or region to tailor settings and services
                  accordingly.
                </p>
              </article>

              <Controller
                name="country_id"
                control={control}
                render={({ field }) => {
                  return (
                    <Tabs.Root
                      value={field.value}
                      onValueChange={field.onChange}
                      format={{ type: 'segmented' }}
                    >
                      <Tabs.List styleVariants={{ hAlign: 'left' }}>
                        {countryOptions.map((v, k) => (
                          <Tabs.Trigger key={k} value={`${v.value}`}>
                            {v.label}
                          </Tabs.Trigger>
                        ))}
                      </Tabs.List>
                    </Tabs.Root>
                  )
                }}
              />
            </article>
          </article>
        </article>

        <article
          className={cn(
            'flex flex-col rounded-lg px-12 py-7',
            defaultBackground,
            defaultBorder
          )}
        >
          <article className="grid lg:grid-cols-2 gap-8">
            <article className="flex flex-col gap-1">
              <p className="font-semibold">ROLE INFO</p>
              <p className="text-xs" style={{ color: colors.neutral[50] }}>
                View detailed information about each group to manage roles and
                permissions effectively.
              </p>
            </article>

            <article className="flex flex-col gap-5">
              <BaseText
                control={control}
                name="name"
                label="Role Name"
                required
              />

              <Controller
                name="group"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <article className="flex flex-col gap-y-1">
                      <label className="text-sm font-medium">
                        Group
                        <Asterisk />
                      </label>
                      <Select
                        {...field}
                        options={groupOptions}
                        error={!!error?.message}
                      />
                      {error?.message && (
                        <p className="text-xs text-[--colors-danger-default]">
                          {error.message}
                        </p>
                      )}
                    </article>
                  )
                }}
              />

              <Textarea
                name="description"
                control={control}
                label="Description"
                required
              />
            </article>
          </article>
        </article>

        <RolePermissions permissions={selectedPermissions} onChange={replace} />

        <FooterAction>
          <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
            <Button
              disabled={updatingRole}
              styleVariants={{ type: 'text' }}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button type="submit" disabled={!isDirty || updatingRole}>
              {updatingRole && <Spinner />}
              Save Changes
            </Button>
          </article>
        </FooterAction>
      </form>

      <RedirectPrompt
        isDirty={isDirty}
        isValid={isValid}
        type="edit"
        loading={updatingRole}
        onConfirm={onSubmit}
      />
    </>
  )
}

export default AppRoleEdit
