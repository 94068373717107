import { typographyOne } from '../style/theme'

export const useTypography = () => {
  // Switch typography using state when there's more than one
  const className = typographyOne

  return {
    className
  }
}

export default useTypography
