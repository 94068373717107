import { cn, formatNumber } from '$app/utils'
import TodayBadge from '$blocks/AppHome/components/TodayBadge'
import TodayCard from '$blocks/AppHome/components/TodayCard'
import { TAppHome } from '$services/api'
import {
  card,
  defaultTitleFontWeight,
  description,
  title
} from '$styles/common.css'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { getButtonVariant, getDailyActiveUserOption } from './utils'
import { WEEK_TYPE } from './types'
import { Button } from '@genie-fintech/ui/components'
import { isSameDay } from 'date-fns'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const { colors } = themeVars

interface IProps {
  active_users: TAppHome['active_users']
  today_active_user_count: TAppHome['today_active_user_count']
  onChangeWeek: (weekType: WEEK_TYPE) => void
  currentDate: Date
}

const Dot = ({ isActive }: { isActive: boolean }) => (
  <span
    className="w-1 h-1 rounded-full"
    style={{
      background: isActive
        ? colors.primary[markedDefaultKey]
        : colors.neutral[60]
    }}
  />
)

const DailyActiveUserChart = ({
  today_active_user_count,
  active_users,
  currentDate,
  onChangeWeek
}: IProps) => {
  const now = new Date()

  const isSameDate = isSameDay(currentDate, now)

  return (
    <article className={cn('flex-1 flex flex-col p-6 gap-6', card)}>
      <article className="flex-1 flex gap-4">
        <article className="flex-1 flex flex-col gap-1">
          <p className={title['type3']}>APPLICATION DAILY ACTIVE USERS</p>

          <article className="flex items-center gap-2">
            <p className={defaultTitleFontWeight} style={{ fontSize: 28 }}>
              {formatNumber(today_active_user_count)}
            </p>
            <TodayBadge />
          </article>

          <p className={description}>
            Analyze daily active users to track application engagement and usage
            trends.
          </p>
        </article>

        <TodayCard />
      </article>

      <article className="flex flex-col gap-4">
        <div style={{ width: '100%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={getDailyActiveUserOption(active_users)}
          />
        </div>

        <article className="flex justify-end items-center">
          <Button
            styleVariants={getButtonVariant(!isSameDate)}
            onClick={() => onChangeWeek(WEEK_TYPE.LAST_WEEK)}
          >
            <Dot isActive={!isSameDate} />
            <span>Last Week</span>
          </Button>

          <Button
            styleVariants={getButtonVariant(isSameDate)}
            onClick={() => onChangeWeek(WEEK_TYPE.THIS_WEEK)}
          >
            <Dot isActive={isSameDate} />
            <span>This Week</span>
          </Button>
        </article>
      </article>
    </article>
  )
}

export default DailyActiveUserChart
