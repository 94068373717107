import { cn, formatFileSize } from '$app/utils'
import { defaultBackground } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { IProps as IDragUploadProps } from '../DragUpload/types'
import { Spinner } from '@genie-fintech/ui/components'
import { FolderCheck, Paperclip } from 'lucide-react'
import { getFileNameWithExt } from '../../utils'

const { colors } = themeVars

type TProps = Omit<IDragUploadProps, 'onSelectFile'>

const FileCard = ({ selectedFile, loading }: TProps) => {
  const message = loading
    ? 'Documents uploading...'
    : 'Documents uploaded successfully.'

  return (
    <article className="flex flex-col w-[400px] gap-3">
      <article
        className={cn(
          'flex items-center gap-4 p-3 rounded-xl border border-dashed',
          defaultBackground
        )}
        style={{ borderColor: colors.neutral[20] }}
      >
        <article
          className="inline-flex items-center justify-center w-16 h-16 rounded-lg"
          style={{
            background: colors.alphaPrimary[1],
            color: colors.primary[80]
          }}
        >
          {loading ? <Spinner size="24" /> : <FolderCheck size={24} />}
        </article>

        <p className="font-medium text-sm">{message}</p>
      </article>

      {selectedFile && (
        <article
          className="flex gap-2 text-sm"
          style={{ color: colors.neutral[60] }}
        >
          <Paperclip size={20} />

          <span className="flex-1 font-medium">
            {getFileNameWithExt(selectedFile.file_name, selectedFile.file.type)}
          </span>

          <span>{formatFileSize(selectedFile.file.size)}</span>
        </article>
      )}
    </article>
  )
}

export default FileCard
