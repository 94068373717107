import { useEffect } from 'react'

export const useHtmlClass = (
  classNames: string[],

  /**
   * Invoked when classes are added.
   *
   * Cleanup: Returned function will invoke upon classes removed.
   */
  callback?: () => VoidFunction | void
) => {
  useEffect(() => {
    const HTML = document.documentElement

    classNames.forEach(className => {
      if (HTML.classList.contains(className)) return

      HTML.classList.add(className)
    })

    const cleanup = callback?.()

    return () => {
      classNames.forEach(className => {
        if (!HTML.classList.contains(className)) return

        HTML.classList.remove(className)
      })

      cleanup?.()
    }
  }, [classNames, callback])
}

export default useHtmlClass
