import { TOOLTIP_ID } from '$app/constants'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { TextSelect } from 'lucide-react'

const DescriptionTableColumn = ({ desc }: { desc?: string }) => {
  return (
    <article
      className="inline-flex items-center gap-2 cursor-pointer"
      data-tooltip-id={TOOLTIP_ID}
      data-tooltip-content={desc}
    >
      <TextSelect size={20} color={themeVars.colors.text.light} />
    </article>
  )
}

export default DescriptionTableColumn
