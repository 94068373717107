import { themeVars } from '@genie-fintech/ui/style/theme'
import { FolderOpen } from 'lucide-react'
import React from 'react'
import FileUploadButton from '../FileUploadButton'

const { colors } = themeVars

interface IProps {
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Line = () => (
  <span
    className="flex-1"
    style={{ borderTop: `1px solid ${colors.neutral[10]}` }}
  />
)

const EmptyFileView = ({ onChangeFile }: IProps) => {
  return (
    <article className="flex flex-col gap-6 items-center">
      <article
        className="inline-flex w-16 h-16 justify-center items-center rounded-xl"
        style={{
          background: colors.alphaPrimary[1],
          color: colors.primary[80]
        }}
      >
        <FolderOpen size={24} />
      </article>

      <article className="flex flex-col gap-2 items-center">
        <p
          className="font-medium text-sm"
          style={{ color: colors.text.disabled }}
        >
          Drop your .CSV file onto this area to upload
        </p>

        <article className="flex gap-4 items-center w-[200px]">
          <Line />
          <span
            className="text-sm font-medium"
            style={{ color: colors.neutral[50] }}
          >
            Or
          </span>
          <Line />
        </article>

        <FileUploadButton
          btnText="Click to Upload"
          onChangeFile={onChangeFile}
        />
      </article>
    </article>
  )
}

export default EmptyFileView
