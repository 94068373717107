import { cn } from '$app/utils'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { ChangeEvent, DragEvent, FC, useCallback, useState } from 'react'
import { ALLOWED_FILE_TYPES } from '../../constants'
import EmptyFileView from '../EmptyFileView'
import { IProps } from './types'
import FileCard from '../FileCard'

const { colors } = themeVars

enum DRAG_TYPE {
  ENTER = 'dragenter',
  OVER = 'dragover',
  LEAVE = 'dragleave'
}

const allowedFileTypes = ALLOWED_FILE_TYPES.map(v => '.' + v.name).join(', ')

const DragUpload: FC<IProps> = ({ selectedFile, onSelectFile, loading }) => {
  const [isDragging, setIsDragging] = useState<boolean>(false)

  const onDrag = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (event.type === DRAG_TYPE.ENTER || event.type === DRAG_TYPE.OVER) {
      setIsDragging(true)
      return
    }

    if (event.type === DRAG_TYPE.LEAVE) {
      setIsDragging(false)
      return
    }
  }, [])

  const onDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      setIsDragging(false)

      onSelectFile(event.dataTransfer.files)
    },
    [onSelectFile]
  )

  const onChangeFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.currentTarget
      onSelectFile(files)
    },
    [onSelectFile]
  )

  const isInitialState = selectedFile === null && !loading

  return (
    <article className="flex flex-col gap-4">
      <article
        onDragEnter={onDrag}
        onDragOver={onDrag}
        onDragLeave={onDrag}
        onDrop={onDrop}
        className={cn('flex flex-col items-center gap-6 py-5 rounded-lg')}
        style={{
          background: colors.alphaArea.disabled,
          borderWidth: 1,
          borderStyle: isDragging ? 'border-dashed' : 'border-solid',
          borderColor: isDragging
            ? colors.neutral[markedDefaultKey]
            : colors.neutral[10]
        }}
      >
        {isInitialState && <EmptyFileView onChangeFile={onChangeFile} />}

        {!isInitialState && (
          <FileCard loading={loading} selectedFile={selectedFile} />
        )}
      </article>

      <p className="inline-flex items-center gap-1 text-xs">
        <span style={{ color: colors.neutral[50] }}>Supports doc type is</span>
        <span className="font-semibold">{allowedFileTypes} file only</span>
      </p>
    </article>
  )
}

export default DragUpload
