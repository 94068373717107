import { Button } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { IMPORT_TYPE } from '../../types'

const { colors } = themeVars

const Header = ({ type }: { type: IMPORT_TYPE }) => {
  return (
    <header className="flex justify-between">
      <article className="flex flex-col gap-1">
        <p className="inline-flex gap-1 font-semibold">
          <span className="capitalize">{type}</span>
          <span>Import .CSV file here!</span>
        </p>
        <p className="text-xs" style={{ color: colors.neutral[50] }}>
          You can explore the data table format by downloading this template.
        </p>
        <p className="text-xs" style={{ color: colors.neutral[50] }}>
          If the unique data be same, we will replace those data.
        </p>
      </article>

      <article className="flex items-end">
        <Button styleVariants={{ type: 'text' }} disabled>
          Download example .CSV template
        </Button>
      </article>
    </header>
  )
}

export default Header
