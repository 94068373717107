import { cn } from '$app/utils'
import {
  AdvanceSettingIcon,
  BasicSettingIcon,
  GroupIcon,
  HomeIcon,
  LaunchPadIcon,
  PermissionIcon,
  RoleIcon,
  TokenIcon,
  UserIcon
} from '$assets/svg'
import AppSwitcher from '$components/AppSwitcher'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { onOneOf, ROUTE_NAMES, RouteNames } from '$router/config'
import Link from '$router/Link'
import { defaultBackground, defaultBorder } from '$styles/common.css'
import { Globe2, Key } from 'lucide-react'
import { ReactNode } from 'react'

const APP_SIDE_MENUS: {
  name: string
  path: RouteNames
  Icon: ReactNode
  subPath?: RouteNames[]
}[] = [
  {
    name: 'Home',
    path: ROUTE_NAMES.APP_HOME,
    Icon: <HomeIcon />
  },
  {
    name: 'Basic Setting',
    path: ROUTE_NAMES.APP_BASIC_SETTING,
    Icon: <BasicSettingIcon />
  },
  {
    name: 'Advanced Setting',
    path: ROUTE_NAMES.APP_ADVANCED_SETTING,
    Icon: <AdvanceSettingIcon />
  },
  {
    name: 'Launchpad',
    path: ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
    Icon: <LaunchPadIcon />
  },
  {
    name: 'Token',
    path: ROUTE_NAMES.APP_TOKEN_SETTING,
    Icon: <TokenIcon />
  },
  {
    name: 'Group',
    path: ROUTE_NAMES.APP_GROUPS,
    Icon: <GroupIcon />,
    subPath: [
      ROUTE_NAMES.APP_GROUP_CREATE,
      ROUTE_NAMES.APP_GROUP_DETAIL,
      ROUTE_NAMES.APP_GROUP_EDIT
    ]
  },
  {
    name: 'Users',
    path: ROUTE_NAMES.APP_USERS,
    Icon: <UserIcon />,
    subPath: [
      ROUTE_NAMES.APP_USER_CREATE,
      ROUTE_NAMES.APP_USER_DETAIL,
      ROUTE_NAMES.APP_USER_EDIT
    ]
  },
  {
    name: 'Roles',
    path: ROUTE_NAMES.APP_ROLES,
    Icon: <RoleIcon />,
    subPath: [
      ROUTE_NAMES.APP_ROLE_CREATE,
      ROUTE_NAMES.APP_ROLE_DETAIL,
      ROUTE_NAMES.APP_ROLE_EDIT
    ]
  },
  {
    name: 'Permissions',
    path: ROUTE_NAMES.APP_PERMISSIONS,
    Icon: <PermissionIcon />,
    subPath: [
      ROUTE_NAMES.APP_PERMISSION_CREATE,
      ROUTE_NAMES.APP_PERMISSION_DETAIL,
      ROUTE_NAMES.APP_PERMISSION_EDIT
    ]
  }
]

const GLOBAL_SETTING_SIDE_MENUS: {
  name: string
  path: RouteNames
  Icon: ReactNode
  subPath?: RouteNames[]
}[] = [
  {
    name: 'Password Policy',
    path: ROUTE_NAMES.PASSWORD_POLICY,
    Icon: <Key size={18} />,
    subPath: []
  },
  {
    name: 'Countries',
    path: ROUTE_NAMES.COUNTRIES,
    Icon: <Globe2 size={18} />,
    subPath: [ROUTE_NAMES.COUNTRY_CREATE, ROUTE_NAMES.COUNTRY_EDIT]
  }
]

const Sidebar = () => {
  const { route } = useRouteSummary()

  const SIDE_MENUS = (() => {
    if (
      onOneOf([
        ROUTE_NAMES.PASSWORD_POLICY,
        ROUTE_NAMES.COUNTRIES,
        ROUTE_NAMES.COUNTRY_CREATE,
        ROUTE_NAMES.COUNTRY_EDIT
      ])
    ) {
      return GLOBAL_SETTING_SIDE_MENUS
    }
    return APP_SIDE_MENUS
  })()

  const isShowAppSwitcher = APP_SIDE_MENUS.map(v => [
    v.path,
    ...(v.subPath ?? [])
  ])
    .flat()
    .includes(route.name!)

  return (
    <aside
      className={cn(
        'flex fixed flex-col py-2 w-[236px] gap-y-4 rounded-lg',
        defaultBackground,
        defaultBorder
      )}
    >
      {isShowAppSwitcher && (
        <article className="px-2">
          <AppSwitcher />
        </article>
      )}

      <article className="flex flex-1 flex-col gap-y-2">
        {SIDE_MENUS.map((menu, key) => {
          const active = onOneOf(
            menu.subPath ? [menu.path, ...menu.subPath] : [menu.path]
          )
          return (
            <article className="relative px-2" key={key}>
              <Link
                to={menu.path}
                options={{ ...route }}
                data-active={active}
                className={cn(
                  'flex gap-x-2 p-3 items-center text-sm text-[--colors-neutral-60] rounded',
                  'data-[active=true]:bg-[--colors-area-low] data-[active=true]:font-semibold data-[active=true]:text-[--colors-text-light]',
                  'data-[active=false]:hover:bg-[--colors-area-low] transition-all duration-200'
                )}
              >
                {menu.Icon}

                <p>{menu.name}</p>

                {active && (
                  <span className="w-[3px] h-full bg-[--colors-primary-default] absolute right-0 rounded-l-lg" />
                )}
              </Link>
            </article>
          )
        })}
      </article>
    </aside>
  )
}

export default Sidebar
