import { useCallback, useState } from 'react'
import { IMPORT_TYPE, TInvalidRecord } from '../types'
import { TUploadFileData, uploadFile } from '$app/utils'
import { validateFile } from '../utils'
import { errorMessageResolver, errorStatusResolver, TID } from '$services/api'
import { toast } from 'sonner'
import { useImportService } from '$hooks/services'

export const useUploadAndImportFile = ({
  appId,
  importType
}: {
  appId?: TID
  importType: IMPORT_TYPE
}) => {
  const [uploading, setUploading] = useState(false)

  const [invalidRecords, setInvalidRecords] = useState<TInvalidRecord[]>([])

  const [uploadedFileData, setUploadedFileData] =
    useState<TUploadFileData | null>(null)

  const { importDataAsync } = useImportService()

  const onSelectFile = useCallback(
    async (fileList: FileList | null) => {
      if (!appId) return

      setInvalidRecords([])

      setUploadedFileData(null)

      const [file] = fileList ?? []

      const isValid = validateFile(file)

      if (!isValid) return

      setUploading(true)

      try {
        const result = await uploadFile(file)

        setUploadedFileData(result)

        await importDataAsync({
          appId,
          type: importType,
          file: result.key
        }).catch(err => {
          const status = errorStatusResolver(err)

          if (status === 422) {
            const records = (err?.response?.data?.errors?.data ??
              []) as unknown as TInvalidRecord[]

            if (records.length) {
              setInvalidRecords(records)
              return
            }
          }

          setUploadedFileData(null)
        })
      } catch (error) {
        toast.error(errorMessageResolver(error))

        setUploadedFileData(null)
      } finally {
        setUploading(false)
      }
    },
    [importDataAsync, appId, importType]
  )

  return {
    uploading,
    invalidRecords,
    uploadedFileData,
    onSelectFile
  }
}
