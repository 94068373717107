import CaptainAppLogo from '$assets/captain_app_logo.svg'
import CarroAppLogo from '$assets/carro_app_logo.svg'
import GenieAppLogo from '$assets/genie_app_logo.svg'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars

export const APP_TOKEN_KEY = 'app-token'

export const TOOLTIP_ID = 'r-tooltip'

export const ACL_OPTIONS = [
  { value: 'role_based', label: 'ROLE BASED (Genie)' },
  { value: 'permission_based', label: 'PERMISSION BASED (Carro)' }
]

export const BREADCRUMB_BAR_ID = 'breadcrumb-bar'

export const GENDER_OPTIONS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' }
]

export const USER_STATUS_TABS = [
  { label: 'ACTIVE', value: 'active' },
  { label: 'INACTIVE', value: 'inactive' }
]

export const APP_ENVIRONMENT_OPTIONS = [
  {
    key: 1,
    value: 'development'
  },
  {
    key: 2,
    value: 'local'
  },
  {
    key: 3,
    value: 'staging'
  },
  {
    key: 4,
    value: 'uat'
  },
  {
    key: 5,
    value: 'production'
  }
]

export const APP_BRANDS = [
  {
    name: 'captain',
    Icon: CaptainAppLogo,
    backgroundColor: colors.alphaPrimary[1]
  },
  {
    name: 'carro',
    Icon: CarroAppLogo,
    backgroundColor: 'hsla(18,100%,50%,0.1)'
  },
  {
    name: 'genie',
    Icon: GenieAppLogo,
    backgroundColor: colors.alphaPrimary[1]
  }
]

export const MIN_PWD_LENGTH = 6

export const MAX_PWD_LENGTH = 30

export const ALLOWED_SPECIAL_CHARACTERS = [
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*'
]
