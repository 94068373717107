import { useAuthContext } from '$contexts/AuthContext/hooks'
import { useLoginLogoutService } from '$hooks/services'
import { Icon } from '@genie-fintech/ui/icons'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {
  AvatarStyle,
  chevronStyle,
  ProfileButtonStyle,
  ProfileContentStyle
} from './styles.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { assignInlineVars } from '@vanilla-extract/dynamic'
import { cn } from '$app/utils'
import { defaultBorder } from '$styles/common.css'
import { LogOut, User2 } from 'lucide-react'
import { backgrounColor } from '$layouts/PageLayout/styles.css'
import { useCallback } from 'react'
import { redirect, ROUTE_NAMES } from '$router/config'

const { colors } = themeVars

const Profile = () => {
  const { user } = useAuthContext()

  const { logout } = useLoginLogoutService()

  const goToProfile = useCallback(() => {
    if (!user?.id) return
    redirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL, {
      params: { dashboardUserId: user.id }
    })
  }, [user?.id])

  if (!user) return null

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className={ProfileButtonStyle}>
          <article className={AvatarStyle({ isActive: true })}>
            <Icon namespace="User" color="absolute.light" width={16} />
          </article>

          <article className="flex flex-col text-left max-w-[150px]">
            <p className="text-sm font-medium truncate">{user.name}</p>
            <p className="text-xs capitalize">{user.role}</p>
          </article>

          <Icon
            namespace="Down"
            color="absolute.light"
            width={18}
            className={chevronStyle}
          />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className={ProfileContentStyle} sideOffset={5}>
          <article className="flex p-4 items-center gap-1.5">
            <article
              className={AvatarStyle()}
              style={assignInlineVars({
                [backgrounColor]: colors.area.disabled
              })}
            >
              <Icon namespace="User" color="text.light" width={16} />
            </article>

            <article className="flex flex-col text-left">
              <p
                className="text-sm font-medium"
                style={{ color: colors.text.light }}
              >
                {user.name}
              </p>
              <p className="text-xs" style={{ color: colors.neutral[60] }}>
                {user.email}
              </p>
            </article>
          </article>

          <button
            className={cn(
              'flex w-full items-center px-5 py-4 border-x-0 gap-2',
              defaultBorder
            )}
            onClick={goToProfile}
            style={{ color: colors.text.light }}
          >
            <User2 size={20} />
            <span className="text-sm font-medium">Your Profile</span>
          </button>

          <button
            className={'flex items-center px-5 py-4 gap-2'}
            style={{ color: colors.text.light }}
            onClick={logout}
          >
            <LogOut size={20} />
            <span className="text-sm font-medium">Log Out</span>
          </button>

          <article
            className="flex flex-col px-5 py-4 gap-0.5"
            style={{ background: colors.alphaArea.disabled }}
          >
            <p
              className="text-xs font-medium"
              style={{ color: colors.neutral[60] }}
            >
              User's Role
            </p>

            <p
              className="text-sm font-semibold capitalize"
              style={{ color: colors.text.light }}
            >
              {user.role}
            </p>
          </article>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default Profile
