import CountryForm from '$blocks/Country/Form'
import { DEFAULT_FORM_VALUES } from '$blocks/Country/Form/constants'
import Breadcrumb from '$components/Breadcrumb/v2'
import { ROUTE_NAMES } from '$router/config'

const CountryCreate = () => {
  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.GLOBAL_SETTING}
        data={[{ name: `Add New Group` }]}
      />

      <CountryForm defaultValues={DEFAULT_FORM_VALUES} />
    </>
  )
}

export default CountryCreate
