import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'

const ErrorField = ({ text }: { text?: string }) => {
  if (!text) return null

  return (
    <p
      className="text-xs"
      style={{ color: themeVars.colors.danger[markedDefaultKey] }}
    >
      {text}
    </p>
  )
}

export default ErrorField
