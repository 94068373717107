import { FC, PropsWithChildren } from 'react'
import Sidebar from './Sidebar'

const LayoutWithSidebar: FC<PropsWithChildren> = ({ children }) => {
  return (
    <section className="flex flex-col gap-y-2 flex-1">
      <article className="flex-1 flex w-full">
        <Sidebar />

        <article className="flex flex-col ml-[236px] flex-1 pl-2 overflow-x-auto">
          {children}
        </article>
      </article>
    </section>
  )
}

export default LayoutWithSidebar
